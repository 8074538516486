/* eslint-disable mastery/no-fetch */
import { ApolloQueryResult } from '@apollo/client';
import { Shell } from '@components/AutoComplete';
import { MODE } from '@env';
import { useFlagMe53788FeatRefreshDdtMinionrefreshevent } from '@generated/flags/ME-53788-feat-refresh-ddt-minionrefreshevent';
import { KeyValueInfoFragment } from '@generated/fragments/keyValueInfo';
import { TenantConfigInfoFragment } from '@generated/fragments/tenantConfig';
import { TenantConfigQuery } from '@generated/queries/tenantConfig';
import { MinionRefreshEventPayloadFragment } from '@generated/subscriptionFragments/minionRefreshEvent';
import { EventType } from '@generated/subscriptionTypes';
import { useHasHappened } from '@hooks/useHasHappened';
import { useSubscriptionService } from '@hooks/useSubscriptionService';
import { useKeycloak } from '@react-keycloak/web';
import { IS_CYPRESS, SKIP_AUTH } from '@utils/constants';
import {
  REMOVE_BEFORE_MUTATION_KEY,
  getTopLevelErrors,
} from '@utils/graphqlUtils';
import { jsonParse } from '@utils/json';
import { reportCustomSentryError } from '@utils/sentry';
import { HAS_WINDOW } from '@utils/win';
import { GraphQLClient } from 'graphql-request';
import { flatten, isArray, memoize } from 'lodash-es';
import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useSWR from 'swr';
import { syncWithStorage } from 'swr-sync-storage';
import { config } from '../../config';
import { MinionConfigTypes } from '../../minion/types';
import { getAuthHeader } from '../auth/token';
import {
  DEPRECATED_DO_NOT_MODIFY_ExtraTypes,
  DEPRECATED_DO_NOT_MODIFY_ExtraTypesEnum,
} from './extraTypes';

// For reasoning behind syncing, see original PR:
// https://github.com/masterysystems/mastery-frontend/pull/1292
if (HAS_WINDOW) {
  syncWithStorage('local');
}

// TODO: we need to merge typings from the DD api into the federated schema.
// But for now this can suffice to add new terms.

export type TCName =
  | MinionConfigTypes
  | DEPRECATED_DO_NOT_MODIFY_ExtraTypesEnum;
export const TCNameEnum = {
  ...MinionConfigTypes,
  ...DEPRECATED_DO_NOT_MODIFY_ExtraTypesEnum,
};

interface OptionMetadata
  extends Record<string, boolean | string | number | null | undefined> {
  systemDefined?: boolean;
  [REMOVE_BEFORE_MUTATION_KEY]: boolean;
  /** Frontend generated abbreviation for the name, mostly for trailer (equipment) types */
  localAbbr?: string;
}

export interface ExtendedKV extends KeyValueInfoFragment {
  metadata: OptionMetadata;
  shortDisplayName?: string;
  parentTermOptionId?: string;
  externalRefIds?: string[];
}

interface WeakTCFrag extends TenantConfigInfoFragment {
  types: anyOk;
}

interface ExtendedTCI extends WeakTCFrag {
  types: Array<{ options: ExtendedKV[]; name: TCName }>;
}

interface WeakTCQuery extends TenantConfigQuery {
  tenantConfiguration: anyOk;
}

interface ExtendedQuery extends WeakTCQuery {
  tenantConfiguration: Maybe<ExtendedTCI>;
}

type HookConfig = ExtendedQuery['tenantConfiguration'] & {
  refetch: () => Promise<boolean>;
  getOptions: (name: TCName) => ExtendedKV[];
  /** UNSAFE: This gets all dropdown items, regardless of active status. Prefer `getActiveDropdownItems`. */
  unsafeGetAllDropdownItems: (name: TCName) => Shell<ExtendedKV>[];
  getActiveDropdownItems: (name: TCName) => Shell<ExtendedKV>[];
  /** UNSAFE: This gets the first option, regardless of active status. Prefer `getFirstActiveOption` */
  unsafeGetFirstOption: (name: TCName) => ExtendedKV;
  getFirstActiveOption: (name: TCName) => ExtendedKV;
  /** Get an option by id and enum match. This is a convenience method that allows us to not call multiple hooks in views where there are lots of KVT ids. */
  getAnyOptionById: (id: Maybe<string>, name: TCName) => Maybe<ExtendedKV>;
  getDropdownItemById: (
    name: TCName,
    id: Maybe<string>
  ) => Maybe<Shell<ExtendedKV>>;
};

const asyncNoop = async (): Promise<boolean> => true;

const defaultKeyValueObj: ExtendedKV = {
  active: true,
  id: '',
  metadataJson: '{}',
  name: '',
  metadata: {
    [REMOVE_BEFORE_MUTATION_KEY]: true,
  },
};

// ts-unused-exports:disable-next-line
export const defaultContext: HookConfig = {
  types: [],
  refetch: asyncNoop,
  getOptions: () => [],
  unsafeGetAllDropdownItems: () => [],
  getActiveDropdownItems: () => [],
  unsafeGetFirstOption: () => defaultKeyValueObj,
  getFirstActiveOption: () => defaultKeyValueObj,
  getAnyOptionById: () => null,
  getDropdownItemById: () => null,
};

// ts-unused-exports:disable-next-line
export const TenantConfigContext = createContext<HookConfig>(defaultContext);

const useTenantConfigContext = (): HookConfig => {
  return useContext(TenantConfigContext);
};

export type GetOptionByIdFunc = (str: Maybe<string>) => ExtendedKV | undefined;

export interface UseTenantConfigRet {
  options: ExtendedKV[];
  getOptionById: GetOptionByIdFunc;
  getDropdownItemById: (str: Maybe<string>) => Shell<ExtendedKV> | undefined;
  /** UNSAFE: This is the first option, regardless of active status. Prefer `firstActiveOption` */
  unsafeFirstOption: ExtendedKV;
  firstActiveOption: ExtendedKV;
  /** UNSAFE: This is all dropdown items, regardless of active status. Prefer `activeDropdownItems`. */
  unsafeAllDropdownItems: Shell<ExtendedKV>[];
  activeDropdownItems: Shell<ExtendedKV>[];
}

// Overloading the useTenantConfig hook here
// If given no key we should expect just the config back.
export function useTenantConfig(): HookConfig;
export function useTenantConfig(key: TCName): HookConfig & UseTenantConfigRet;
export function useTenantConfig(
  key?: TCName
): HookConfig | (HookConfig & UseTenantConfigRet) {
  const config = useTenantConfigContext();
  const {
    getOptions,
    unsafeGetFirstOption,
    getFirstActiveOption,
    unsafeGetAllDropdownItems,
    getActiveDropdownItems,
  } = config;

  return {
    ...config,
    ...(key && {
      getOptionById: (arg): ExtendedKV | undefined =>
        getOptions(key).find((obj) => obj.id === arg),
      options: getOptions(key),
      unsafeFirstOption: unsafeGetFirstOption(key),
      firstActiveOption: getFirstActiveOption(key),
      unsafeAllDropdownItems: unsafeGetAllDropdownItems(key),
      activeDropdownItems: getActiveDropdownItems(key),
      getDropdownItemById: (arg: string): Shell<ExtendedKV> | undefined =>
        unsafeGetAllDropdownItems(key).find((opt) => opt.value?.id === arg),
    }),
  };
}

const getValues = (
  data: ExtendedQuery | undefined,
  name: TCName
): ExtendedKV[] => {
  const found = (data?.tenantConfiguration?.types || []).find(
    (obj) => obj.name === name
  ) || {
    options: [],
    name,
  };
  return found.options;
};

const unsafeGetFirstOption = (
  data: ExtendedQuery | undefined,
  name: TCName
): ExtendedKV => {
  // This fallback should hopefully never happen.
  // But it should help avoid Type Errors when consumers expect an object, even if we didn't find one in the array.
  return getValues(data, name)[0] || defaultKeyValueObj;
};

const getFirstActiveOption = (
  data: ExtendedQuery | undefined,
  name: TCName
): ExtendedKV => {
  // This fallback should hopefully never happen.
  // But it should help avoid Type Errors when consumers expect an object, even if we didn't find one in the array.
  return (
    getValues(data, name).find((o) => o.active === true) || defaultKeyValueObj
  );
};

const getAnyOptionById = (
  data: ExtendedQuery | undefined,
  id?: Maybe<string>,
  name?: TCName
): Maybe<ExtendedKV> => {
  return name
    ? getDropdownItemById(data, name, id)?.value
    : flatten(data?.tenantConfiguration?.types.map((obj) => obj.options)).find(
        (obj) => obj.id === id
      );
};

// ts-unused-exports:disable-next-line
export const unsafeGetAllDropdownItems = (
  data: ExtendedQuery | undefined,
  name: TCName
): Shell<ExtendedKV>[] =>
  // This will remove from the array any DDT options with 'showInUI: false' set in their metadata
  getValues(data, name)
    .filter((value) => !(value?.metadata?.showInUI === false))
    .map((value) => ({
      value,
      label: value.name,
      id: value.id,
    }));

// ts-unused-exports:disable-next-line
export const getActiveDropdownItems = (
  data: ExtendedQuery | undefined,
  name: TCName
): Shell<ExtendedKV>[] =>
  getValues(data, name)
    .filter((value) => value.active === true)
    .map((value) => ({
      value,
      label: value.name,
      id: value.id,
    }));

const getDropdownItemById = (
  data: ExtendedQuery | undefined,
  name: TCName,
  key: Maybe<string>
): Shell<ExtendedKV> | undefined =>
  unsafeGetAllDropdownItems(data, name).find((obj) => obj.value?.id === key);

const getTrailerTypeAbbr = memoize((str: string) =>
  str.replace(/[^A-Z]+/g, '')
);

/** Data dictionary query is not in federation, so we need to define manually until we can add it in with a localSchema type merge */
const tcDocument = `
query dataDictionary {
  tenantConfiguration: dataDictionary {
    types {
      name
      options {
        id
        active
        metadataJson
        name
        shortDisplayName
        parentTermOptionId
        externalRefIds
      }
    }
  }
}
`;

/** Exclude JWT expired errors from reporting, as they are likely innocuous and handled by our keycloak setup which will redirect to login page automatically */
export const reportErrorIfNotJWTExpired = (err: anyOk): void => {
  const a = getTopLevelErrors(err?.response);
  if (err?.response?.status === 401) {
    return;
  } else if (isArray(a)) {
    const msg = a?.[0]?.message || '';
    if (msg.match(/TokenExpiredError|ExpiredSignature/)) {
      return;
    }
  }
  reportCustomSentryError(err);
};

const generateTypes = (rawData: Maybe<TenantConfigQuery>): ExtendedQuery => {
  // Parse the metadataJson for consumer ease of use
  const rawTypesArr = [...(rawData?.tenantConfiguration?.types || [])];
  const types = rawTypesArr
    .concat(DEPRECATED_DO_NOT_MODIFY_ExtraTypes as fixMe)
    .map((obj) => ({
      name: obj.name,
      options: obj.options.map((opt): ExtendedKV => {
        let metadata: OptionMetadata = { [REMOVE_BEFORE_MUTATION_KEY]: true };
        if (obj.name === 'trailerType') {
          metadata.localAbbr = getTrailerTypeAbbr(opt.name);
        }
        try {
          metadata = {
            ...metadata,
            ...jsonParse(opt.metadataJson, {}),
          };
        } catch {
          // noop
        }
        return { ...opt, metadata };
      }),
    }));

  const data: ExtendedQuery = {
    ...rawData,
    tenantConfiguration: {
      types: types as fixMe,
    },
  };
  return data;
};

const transformForState = (
  data: Maybe<TenantConfigQuery>,
  revalidate?: () => Promise<boolean>
): HookConfig => {
  if (data?.tenantConfiguration) {
    const generated = generateTypes(data);
    if (generated && generated.tenantConfiguration?.types) {
      return {
        types: generated.tenantConfiguration?.types,
        refetch: revalidate ?? ((): Promise<boolean> => Promise.resolve(true)),
        unsafeGetFirstOption: unsafeGetFirstOption.bind(null, generated),
        getFirstActiveOption: getFirstActiveOption.bind(null, generated),
        getOptions: getValues.bind(null, generated),
        unsafeGetAllDropdownItems: unsafeGetAllDropdownItems.bind(
          null,
          generated
        ),
        getActiveDropdownItems: getActiveDropdownItems.bind(null, generated),
        getAnyOptionById: getAnyOptionById.bind(null, generated),
        getDropdownItemById: getDropdownItemById.bind(null, generated),
      };
    }
  }
  return defaultContext;
};

export const TenantConfig: FC = ({ children }) => {
  const flagIsReady = true;
  const {
    keycloak: { authenticated },
  } = useKeycloak();
  const refetchDDTvalues = useFlagMe53788FeatRefreshDdtMinionrefreshevent();

  const isAuthenticated = authenticated || SKIP_AUTH;

  let endpoint = config.dataDictionaryEndpoint;
  if (IS_CYPRESS) {
    endpoint = endpoint + '?dictionary=true';
  }

  const authorization = getAuthHeader();

  const fetcher = useMemo(
    () =>
      async (query: string): Promise<TenantConfigQuery> => {
        const client = new GraphQLClient(endpoint, {
          headers: { authorization },
        });
        const res = await client.request<TenantConfigQuery>(query);
        if (!res.tenantConfiguration?.types.length) {
          throw new Error(
            'Tenant config data returned no types. This potentially means users will be completely unable to use the application.'
          );
        }
        return res;
      },
    [authorization, endpoint]
  );

  const swrDocument = isAuthenticated && flagIsReady ? tcDocument : null;

  const { error, revalidate, data } = useSWR<
    ApolloQueryResult<TenantConfigQuery>['data']
  >(swrDocument, fetcher, {
    onError: (err) => {
      reportErrorIfNotJWTExpired(err);
    },
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    onSuccess: (data) => {
      setState(transformForState(data, revalidate));
    },
  });

  const [state, setState] = useState<HookConfig>(() =>
    transformForState(data, revalidate)
  );

  const loading = !state.types.length && !error;

  const hasLoaded =
    useHasHappened(!loading) || (MODE === 'test' && state.types.length);

  useEffect(() => {
    revalidate();
  }, [revalidate]);

  const revalidateDDTonMinionUpdates = (
    event: MinionRefreshEventPayloadFragment
  ): void => {
    if (event.id && refetchDDTvalues) {
      revalidate();
    }
  };

  /* if DDT gets updated in Minion, trigger a whole sale revalidation of DDT values  */
  useSubscriptionService({
    subscriptionIds: 'deprecatedSubscribeToAll',
    eventHandlers: {
      [EventType.MinionRefreshEvent]: revalidateDDTonMinionUpdates,
    },
  });

  if (hasLoaded) {
    return (
      <TenantConfigContext.Provider value={state}>
        {children}
      </TenantConfigContext.Provider>
    );
  }
  return null;
};
