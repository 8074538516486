import { AutoComplete } from '@components/AutoComplete';
import { Nullable } from '@flatfile/adapter';
import { useSearchCustomersBasicV2LazyQuery } from '@generated/queries/searchCustomersBasicV2';
import { CustomerAddress, CustomerV2 } from '@generated/types';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { searchPhoneNumberMatch } from '@utils/searchPhoneNumberMatch';
import { Component, FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { renderSideBarResults } from '../common';
import { SIDEBAR_SEARCH_STYLE } from '../style';

export type CustomerSearchResult = Pick<
  Partial<CustomerV2>,
  'id' | 'code' | 'name'
> & { mainAddress?: Nullable<Pick<CustomerAddress, 'id' | 'city' | 'state'>> };

const CustomerSearchBase: FC<RouteComponentProps> = ({ history: { push } }) => {
  const [searchCustomersV2, { loading: loadingV2, data: dataV2 }] =
    useSearchCustomersBasicV2LazyQuery();

  const debouncedSearchCustomers = useDebouncedFn(searchCustomersV2, 500, []);

  const customers = dataV2?.allCustomersV2?.edges ?? [];
  const items = customers.map(({ node }) => ({
    value: node,
    label: node.name,
    id: node.id,
  }));

  return (
    <AutoComplete<CustomerSearchResult>
      fsName="customer-search"
      fsType="customer"
      onInputValueChange={(inputValue): void => {
        debouncedSearchCustomers({
          variables: {
            filter: {
              text: searchPhoneNumberMatch(inputValue),
            },
            first: 15,
          },
        });
      }}
      onChange={(item, helpers): void => {
        if (item) {
          helpers?.clearSelection();
          helpers?.closeMenu();
          push(`/customers/${item.id}`);
        }
      }}
      items={items}
      loading={loadingV2}
      renderDropdownInPopper
      DEPRECATEDrenderItem={renderSideBarResults<CustomerSearchResult>()}
      css={{ height: '100%' }}
      showSearchIcon
      inputProps={{
        placeholder: 'Search',
        'data-testid': 'customer-search-input',
        css: SIDEBAR_SEARCH_STYLE,
      }}
    />
  );
};

export const CustomerSearch: typeof Component = withRouter(CustomerSearchBase);
