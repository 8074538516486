import { TableId } from '@components/Table';
import { TablePreferencesSettings } from '@components/Table/util/customizeTable';
import { TablePreferenceResponseType } from '@generated/types';
import { omit } from 'lodash-es';
import { AllTablePreferences, TablePreferencesShape } from '.';
import { IDBActions, idbHasPrefsForTable, idbSavePrefsForTable } from './idb';

/** To convert table Prefs from IndexDB format to tablePreference state */
export const getFormattedPrefs = (
  prefs: TablePreferencesShape[]
): AllTablePreferences => {
  return prefs
    .map((pref) => {
      const prefsColumns =
        pref.columns &&
        pref.columns.map((col) => {
          return omit(col, '__typename');
        });
      return pref.tableName && pref.columns
        ? {
            [`${pref.tableName}` as TableId]: {
              tableId: pref.objectId,
              tableFields: Object.values(
                prefsColumns as TablePreferencesSettings[]
              ) as TablePreferencesSettings[],
              tableName: pref.tableName,
              renderType: pref.renderType,
            },
          }
        : [];
    })
    .reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {}) as AllTablePreferences;
};

/** To convert table Prefs to IndexDB format */
export const convertPrefsToDbFormat = (
  resPrefs: Partial<TablePreferenceResponseType>[]
): TablePreferencesShape[] => {
  const convertedPrefs =
    resPrefs.map(
      (obj) =>
        ({
          objectId: obj.objectId,
          tableName: obj?.tableName,
          columns: obj?.tableFields,
          renderType: obj?.renderType,
        } as TablePreferencesShape)
    ) || [];

  const uniqPrefs = convertedPrefs.reduce<
    Record<number, TablePreferencesShape>
  >((acc, current) => {
    acc[current?.objectId as fixMe] = current;
    return acc;
  }, {});

  return Object.values(uniqPrefs);
};

export const addOrUpdateSinglePrefInIndexDB = async (
  idb: IDBActions,
  singleTablePref: TablePreferencesShape,
  allTablePrefs: AllTablePreferences,
  updatePrefs: (mergedPrefs: AllTablePreferences) => void
): Promise<void> => {
  const exists = idbHasPrefsForTable(singleTablePref.objectId, allTablePrefs);

  // Only sent to IDB if it already exists or we have everything we need for a complete entry.
  if (exists || (singleTablePref.objectId && singleTablePref.columns)) {
    const addedPref = await idbSavePrefsForTable(idb, singleTablePref, !exists);
    const formattedPrefs = getFormattedPrefs([addedPref]);
    updatePrefs(formattedPrefs);
  }
};
