import { asyncDelay } from '@utils/promise';

export const WALKME_TOGGLE_BUTTON_ID = 'walkme-toggle';

/** Attempt to click the WalkMe button. Use a retry pattern due to the async nature of loading the walkme scripts generally. */
export const clickExternalWalkmeButton = async (): Promise<void> => {
  let btn: anyOk | null = null;
  let attempts = 0;

  while (attempts < 3 && !(btn instanceof HTMLElement)) {
    btn = document.querySelector('#walkme-player') as HTMLElement;
    if (btn instanceof HTMLElement) {
      btn.click();
      setTimeout(() => {
        (
          document.querySelector(
            '.wm-menu-search-bar input'
          ) as HTMLInputElement
        )?.focus();
      }, 400);
      return;
    }
    attempts++;
    await asyncDelay(2000);
  }
};
