import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type AssetDriverCurrentHosEventPayloadFragment = (
  { readonly __typename?: 'AssetDriverCurrentHosEvent' }
  & Pick<TP.AssetDriverCurrentHosEvent, 'id' | 'driverId'>
  & { readonly computedCurrentHosClocks?: TP.Maybe<(
    { readonly __typename?: 'ComputedCurrentHosClocks' }
    & Pick<TP.ComputedCurrentHosClocks, 'id'>
    & { readonly driverTime?: TP.Maybe<(
      { readonly __typename?: 'DriverHosTime' }
      & Pick<TP.DriverHosTime, 'remaining' | 'utilized'>
    )>, readonly shiftTime?: TP.Maybe<(
      { readonly __typename?: 'DriverHosTime' }
      & Pick<TP.DriverHosTime, 'remaining' | 'utilized'>
    )>, readonly breakTime?: TP.Maybe<(
      { readonly __typename?: 'DriverHosTime' }
      & Pick<TP.DriverHosTime, 'remaining' | 'utilized'>
    )>, readonly cycleTime?: TP.Maybe<(
      { readonly __typename?: 'DriverHosTime' }
      & Pick<TP.DriverHosTime, 'remaining' | 'utilized'>
    )> }
  )> }
);

export type ComputedCurrentHosClocksSubscriptionInfoFragment = (
  { readonly __typename?: 'ComputedCurrentHosClocks' }
  & Pick<TP.ComputedCurrentHosClocks, 'id'>
  & { readonly driverTime?: TP.Maybe<(
    { readonly __typename?: 'DriverHosTime' }
    & Pick<TP.DriverHosTime, 'remaining' | 'utilized'>
  )>, readonly shiftTime?: TP.Maybe<(
    { readonly __typename?: 'DriverHosTime' }
    & Pick<TP.DriverHosTime, 'remaining' | 'utilized'>
  )>, readonly breakTime?: TP.Maybe<(
    { readonly __typename?: 'DriverHosTime' }
    & Pick<TP.DriverHosTime, 'remaining' | 'utilized'>
  )>, readonly cycleTime?: TP.Maybe<(
    { readonly __typename?: 'DriverHosTime' }
    & Pick<TP.DriverHosTime, 'remaining' | 'utilized'>
  )> }
);

export type DriverHosTimeSubscriptionInfoFragment = (
  { readonly __typename?: 'DriverHosTime' }
  & Pick<TP.DriverHosTime, 'remaining' | 'utilized'>
);

export const DriverHosTimeSubscriptionInfoFragmentDoc = gql`
    fragment DriverHosTimeSubscriptionInfo on DriverHosTime {
  remaining
  utilized
}
    `;
export const ComputedCurrentHosClocksSubscriptionInfoFragmentDoc = gql`
    fragment ComputedCurrentHosClocksSubscriptionInfo on ComputedCurrentHosClocks {
  id
  driverTime {
    ...DriverHosTimeSubscriptionInfo
  }
  shiftTime {
    ...DriverHosTimeSubscriptionInfo
  }
  breakTime {
    ...DriverHosTimeSubscriptionInfo
  }
  cycleTime {
    ...DriverHosTimeSubscriptionInfo
  }
}
    ${DriverHosTimeSubscriptionInfoFragmentDoc}`;
export const AssetDriverCurrentHosEventPayloadFragmentDoc = gql`
    fragment AssetDriverCurrentHosEventPayload on AssetDriverCurrentHosEvent {
  id
  driverId
  computedCurrentHosClocks {
    ...ComputedCurrentHosClocksSubscriptionInfo
  }
}
    ${ComputedCurrentHosClocksSubscriptionInfoFragmentDoc}`;