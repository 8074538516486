import * as TP from '../types';

import { gql } from '@apollo/client';
import { EmployeeInfoV2FragmentDoc } from '../fragments/employeeInfoV2';
import { EmployeeGroupsInfoV2FragmentDoc } from '../fragments/employeeGroupsInfoV2';
import { EmployeeDetailsInfoV2FragmentDoc } from '../fragments/employeeDetailsInfoV2';
import { EmployeeGeneralInfoFragmentDoc } from '../fragments/employeeGeneralInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchEmployeesV2QueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.EmployeeFilterV2>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
  last?: TP.InputMaybe<TP.Scalars['Int']>;
  after?: TP.InputMaybe<TP.Scalars['String']>;
  before?: TP.InputMaybe<TP.Scalars['String']>;
}>;


export type SearchEmployeesV2Query = (
  { readonly __typename?: 'Query' }
  & { readonly allEmployeesV2?: TP.Maybe<(
    { readonly __typename?: 'EmployeeConnectionV2' }
    & { readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & Pick<TP.PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), readonly edges: ReadonlyArray<(
      { readonly __typename?: 'EmployeeEdgeV2' }
      & Pick<TP.EmployeeEdgeV2, 'cursor'>
      & { readonly node: (
        { readonly __typename?: 'EmployeeV2' }
        & Pick<TP.EmployeeV2, 'id' | 'businessUnitId' | 'companyEmail' | 'divisionId' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeCompanyLevelId' | 'employeeDepartmentId' | 'employeeDepartmentLevelId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeRoleId' | 'employeeShirtSizeId' | 'employeeSuffixId' | 'employeeTimeId' | 'employeeTypeId' | 'ext' | 'firstName' | 'firstNameDotLastName' | 'fullName' | 'hireDate' | 'lastName' | 'managerId' | 'middleName' | 'mobilePhone' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'projectId' | 'relationship' | 'slackName' | 'subRegion' | 'termDate' | 'title' | 'userId'>
        & { readonly businessUnit?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name' | 'active'>
        )>, readonly division?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly employeeAdditionalDivisions?: TP.Maybe<ReadonlyArray<TP.Maybe<(
          { readonly __typename?: 'EmployeeAdditionalDivisions' }
          & Pick<TP.EmployeeAdditionalDivisions, 'id' | 'additionalDivisionId' | 'employeeId'>
        )>>>, readonly employeeGroup?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly employeeOffice?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly employeeStatus: (
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'active'>
        ), readonly manager?: TP.Maybe<(
          { readonly __typename?: 'EmployeeV2' }
          & Pick<TP.EmployeeV2, 'companyEmail' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeDepartmentId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeSuffixId' | 'ext' | 'firstName' | 'fullName' | 'id' | 'lastName' | 'middleName' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'title' | 'userId' | 'managerId'>
        )>, readonly project?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name' | 'active'>
        )>, readonly employeeProjects?: TP.Maybe<ReadonlyArray<TP.Maybe<(
          { readonly __typename?: 'EmployeeProject' }
          & Pick<TP.EmployeeProject, 'id'>
          & { readonly employeeProject?: TP.Maybe<(
            { readonly __typename?: 'Project' }
            & Pick<TP.Project, 'id' | 'name'>
          )> }
        )>>>, readonly employeeGroups?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>> }
      ) }
    )> }
  )>, readonly employeeRepV2?: TP.Maybe<(
    { readonly __typename?: 'EmployeeConnectionV2' }
    & { readonly edges: ReadonlyArray<(
      { readonly __typename?: 'EmployeeEdgeV2' }
      & Pick<TP.EmployeeEdgeV2, 'cursor'>
      & { readonly node: (
        { readonly __typename?: 'EmployeeV2' }
        & Pick<TP.EmployeeV2, 'id' | 'businessUnitId' | 'companyEmail' | 'divisionId' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeCompanyLevelId' | 'employeeDepartmentId' | 'employeeDepartmentLevelId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeRoleId' | 'employeeShirtSizeId' | 'employeeSuffixId' | 'employeeTimeId' | 'employeeTypeId' | 'ext' | 'firstName' | 'firstNameDotLastName' | 'fullName' | 'hireDate' | 'lastName' | 'managerId' | 'middleName' | 'mobilePhone' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'projectId' | 'relationship' | 'slackName' | 'subRegion' | 'termDate' | 'title' | 'userId' | 'allDivisions'>
        & { readonly businessUnit?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name' | 'active'>
        )>, readonly division?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly employeeBusinessUnits?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>>, readonly employeeDivisions?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>>, readonly employeeAdditionalDivisions?: TP.Maybe<ReadonlyArray<TP.Maybe<(
          { readonly __typename?: 'EmployeeAdditionalDivisions' }
          & Pick<TP.EmployeeAdditionalDivisions, 'id' | 'additionalDivisionId' | 'employeeId'>
        )>>>, readonly employeeUserDetail?: TP.Maybe<(
          { readonly __typename?: 'UserV2' }
          & Pick<TP.UserV2, 'id'>
          & { readonly memberships?: TP.Maybe<ReadonlyArray<TP.Maybe<(
            { readonly __typename?: 'Membership' }
            & Pick<TP.Membership, 'organizationID'>
            & { readonly organization?: TP.Maybe<(
              { readonly __typename?: 'Organization' }
              & Pick<TP.Organization, 'id' | 'name'>
            )> }
          )>>> }
        )>, readonly employeeGroup?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly employeeOffice?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly employeeStatus: (
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'active'>
        ), readonly manager?: TP.Maybe<(
          { readonly __typename?: 'EmployeeV2' }
          & Pick<TP.EmployeeV2, 'companyEmail' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeDepartmentId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeSuffixId' | 'ext' | 'firstName' | 'fullName' | 'id' | 'lastName' | 'middleName' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'title' | 'userId' | 'managerId'>
        )>, readonly project?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name' | 'active'>
        )>, readonly employeeProjects?: TP.Maybe<ReadonlyArray<TP.Maybe<(
          { readonly __typename?: 'EmployeeProject' }
          & Pick<TP.EmployeeProject, 'id'>
          & { readonly employeeProject?: TP.Maybe<(
            { readonly __typename?: 'Project' }
            & Pick<TP.Project, 'id' | 'name'>
          )> }
        )>>>, readonly employeeGroups?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>> }
      ) }
    )> }
  )> }
);


export const SearchEmployeesV2Document = gql`
    query searchEmployeesV2($filter: EmployeeFilterV2, $first: Int, $last: Int, $after: String, $before: String) {
  allEmployeesV2(
    filter: $filter
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...EmployeeInfoV2
        ...EmployeeGroupsInfoV2
      }
    }
  }
  employeeRepV2: allEmployeesV2(
    filter: $filter
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    edges {
      cursor
      node {
        ...EmployeeDetailsInfoV2
        ...EmployeeGeneralInfo
      }
    }
  }
}
    ${EmployeeInfoV2FragmentDoc}
${EmployeeGroupsInfoV2FragmentDoc}
${EmployeeDetailsInfoV2FragmentDoc}
${EmployeeGeneralInfoFragmentDoc}`;

/**
 * __useSearchEmployeesV2Query__
 *
 * To run a query within a React component, call `useSearchEmployeesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useSearchEmployeesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchEmployeesV2Query({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useSearchEmployeesV2Query(baseOptions?: Apollo.QueryHookOptions<SearchEmployeesV2Query, SearchEmployeesV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchEmployeesV2Query, SearchEmployeesV2QueryVariables>(SearchEmployeesV2Document, options);
      }
export function useSearchEmployeesV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchEmployeesV2Query, SearchEmployeesV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchEmployeesV2Query, SearchEmployeesV2QueryVariables>(SearchEmployeesV2Document, options);
        }
export type SearchEmployeesV2QueryHookResult = ReturnType<typeof useSearchEmployeesV2Query>;
export type SearchEmployeesV2LazyQueryHookResult = ReturnType<typeof useSearchEmployeesV2LazyQuery>;
export type SearchEmployeesV2QueryResult = Apollo.QueryResult<SearchEmployeesV2Query, SearchEmployeesV2QueryVariables>;