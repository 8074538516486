import { useWhoAmI } from '@app/WhoAmI';
import { Dialog } from '@components/Dialog';
import { useModalState } from '@components/shared/Modal';
import { EmployeeDetailsEmployeeTreeV2Fragment } from '@generated/fragments/employeeDetailsEmployeeTreeV2';
import { EmployeeInfoV2Fragment } from '@generated/fragments/employeeInfoV2';
import { useGetEmployeeDetailsV2LazyQuery } from '@generated/queries/getEmployeeDetailsV2';
import { useGetEmployeeParentDetailsV2LazyQuery } from '@generated/queries/getEmployeeParentDetailsV2';
import { useTheme } from '@hooks/useTheme';
import { useViewSlackEmployeeTreeFlag } from '@hooks/useViewSlackEmployeeTreeView';
import { ModalData } from '@views/Load/RoutesSection/modalData';
import { useGetMessagingServiceStatus } from '@views/SendMessages/SendMessageContainer/useGetMessagingServiceStatus';
import { compact, isNil } from 'lodash-es';
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Loading } from '../../../components/Loading';
import { TreeNode } from '../../../components/TreePicker';
import { EmployeeTreeNodeValue, SidebarContext } from '../util';
import { EmployeeTreePicker } from './employeeTreePicker';
import { getEmployeeTreeNodes, prioritizeEmployeeTreeNodes } from './util';

interface Props {
  updateEmployeeTreeNodeValue: (emp: EmployeeTreeNodeValue) => void;
  employee: EmployeeInfoV2Fragment;
}

export const EmployeeTree = ({
  updateEmployeeTreeNodeValue,
  employee,
}: Props): ReactElement => {
  const { employee: currentEmployee } = useWhoAmI();
  const { employeeTreeNodeValue } = useContext(SidebarContext);
  const [employeeTreeNodes, setEmployeeTreeNodes] = useState<
    TreeNode<EmployeeTreeNodeValue>[]
  >([]);

  const [loading, setLoading] = useState(false);

  const [selectedEmployee, setSelectedEmployee] =
    useState<EmployeeDetailsEmployeeTreeV2Fragment>();

  const useViewSlackEmployeeTreeView = useViewSlackEmployeeTreeFlag();
  const { gray } = useTheme();
  const grayColor = gray[30];
  const { isModalActive, openModal, closeModal } = useModalState();
  const selectedEmployeeIsActive: string = employee?.employeeStatus?.id;
  const modalCommonStyle = {
    width: 550,
    paddingBottom: 48,
  };

  const styleWhenTeams = {
    height: 450,
    padding: '16px',
  };

  const [fireEmployeeDetailsQueryV2, { error: errorEmployeeDetailsQueryV2 }] =
    useGetEmployeeDetailsV2LazyQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      onError: () => {
        setLoading(false);
      },
      onCompleted: ({ employeeV2 }) => {
        setEmployeeTreeNodes(
          prioritizeEmployeeTreeNodes(
            getEmployeeTreeNodes(
              employeeV2?.parent
                ? [employeeV2.parent]
                : employeeV2
                ? [employeeV2]
                : undefined,
              useViewSlackEmployeeTreeView,
              grayColor,
              openModal,
              selectedEmployeeIsActive,
              setSelectedEmployee,
              isTeamsMinionEnabled,
              isSlackMinionEnabled
            ),
            employeeV2?.id,
            employeeV2?.manager?.id
          )
        );
        setLoading(false);
        updateEmployeeTreeNodeValue({
          id: employeeV2?.id || '',
          userId: employeeV2?.userId || '',
          fullName: employeeV2?.fullName || '',
          employeeRoleId: employeeV2?.employeeRole?.id,
          division: employeeV2?.division,
          employeeAdditionalDivisions: compact(
            employeeV2?.employeeAdditionalDivisions
          ),
          employeeDepartmentId: employeeV2?.employeeDepartmentId,
        });
      },
    });

  const [
    fireEmployeeParentDetailsQueryV2,
    { error: errorEmployeeParentDetailsQueryV2 },
  ] = useGetEmployeeParentDetailsV2LazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: () => {
      setLoading(false);
    },
    onCompleted: ({ employeeV2 }) => {
      setEmployeeTreeNodes(
        prioritizeEmployeeTreeNodes(
          getEmployeeTreeNodes(
            employeeV2?.parent?.siblings,
            useViewSlackEmployeeTreeView,
            grayColor,
            openModal,
            selectedEmployeeIsActive,
            setSelectedEmployee,
            isTeamsMinionEnabled,
            isSlackMinionEnabled
          ),
          employeeV2?.id,
          employeeV2?.manager?.id
        )
      );
      setLoading(false);
      updateEmployeeTreeNodeValue({
        id: employeeV2?.id || '',
        userId: employeeV2?.userId || '',
        fullName: employeeV2?.fullName || '',
        employeeRoleId: employeeV2?.employeeRole?.id,
        division: employeeV2?.division,
        employeeAdditionalDivisions: compact(
          employeeV2?.employeeAdditionalDivisions
        ),
        employeeDepartmentId: employeeV2?.employeeDepartmentId,
      });
    },
  });

  const fireQuery = useCallback(
    (employee) => {
      if (employee?.managerId) {
        fireEmployeeParentDetailsQueryV2({
          variables: {
            id: employee?.id || '',
            useViewSlackEmployeeTreeFlag: useViewSlackEmployeeTreeView,
          },
        });
      } else {
        fireEmployeeDetailsQueryV2({
          variables: {
            id: employee?.id || '',
            useViewSlackEmployeeTreeFlag: useViewSlackEmployeeTreeView,
          },
        });
      }
    },
    [
      fireEmployeeDetailsQueryV2,
      fireEmployeeParentDetailsQueryV2,
      useViewSlackEmployeeTreeView,
    ]
  );

  useEffect(() => {
    if (employee) {
      setLoading(true);
      fireQuery(employee);
    }
  }, [employee, fireQuery]);

  useEffect(() => {
    if (currentEmployee && isNil(employeeTreeNodeValue)) {
      updateEmployeeTreeNodeValue({
        id: currentEmployee?.id || '',
        userId: currentEmployee?.userId || '',
        fullName: currentEmployee?.fullName || '',
        employeeRoleId: currentEmployee?.employeeRoleId,
        division: currentEmployee?.division,
        employeeAdditionalDivisions: compact(
          currentEmployee?.employeeAdditionalDivisions
        ),
        employeeDepartmentId: currentEmployee?.employeeDepartmentId,
      });
    }
  }, [currentEmployee, employeeTreeNodeValue, updateEmployeeTreeNodeValue]);

  const [isTeamsMinionEnabled] =
    useGetMessagingServiceStatus('msTeamsMessaging');

  const [isSlackMinionEnabled] = useGetMessagingServiceStatus('slackMessaging');

  return (
    <div style={{ width: '98%' }}>
      {errorEmployeeParentDetailsQueryV2 || errorEmployeeDetailsQueryV2 ? (
        <div>Error...</div>
      ) : loading ? (
        <Loading delay={2000} />
      ) : (
        <EmployeeTreePicker
          updateEmployeeTreeNodeValue={updateEmployeeTreeNodeValue}
          employeeTreeNodeValue={employeeTreeNodeValue}
          employee={employee}
          currentEmployee={currentEmployee as EmployeeInfoV2Fragment}
          employeeTreeNodes={employeeTreeNodes}
        />
      )}
      <Dialog
        id="employee-tree"
        active={isModalActive}
        closeModal={(): void => {
          closeModal();
        }}
        contentStyle={
          isTeamsMinionEnabled
            ? { ...modalCommonStyle, ...styleWhenTeams }
            : { ...modalCommonStyle }
        }
      >
        <ModalData
          modalMetaVal={'ShareObject'}
          shareModalTitle={'Share Message'}
          objectId={''}
          closeModal={closeModal}
          modalObj={{}}
          entityEnum={''}
          toButtonWidth={430}
          selEmpDet={selectedEmployee}
        />
      </Dialog>
    </div>
  );
};
