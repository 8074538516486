import { AutoComplete } from '@components/AutoComplete';
import { noop } from 'lodash-es';
import { lazy, ReactElement, Suspense } from 'react';
import { SIDEBAR_SEARCH_STYLE } from '../style';

const Component = lazy(() =>
  import('./component').then((module) => ({ default: module.LoadSearchV2 }))
);

export const LoadSearchV2 = (): ReactElement => (
  <Suspense
    fallback={
      <AutoComplete
        fsName="load-search"
        showSearchIcon
        items={[]}
        onChange={noop}
        css={{ height: '100%' }}
        renderDropdownInPopper
        disabled
        inputProps={{
          placeholder: 'Search',
          css: SIDEBAR_SEARCH_STYLE,
        }}
      />
    }
  >
    <Component />
  </Suspense>
);
