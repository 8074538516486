import { useMount } from 'react-use';

export const removeRootLoadingClass = (): void => {
  // eslint-disable-next-line mastery/no-dom-manipulation
  document.querySelector('#root')?.classList.remove('loading');
};

/** useBoot signifies to the boot shell that the application is ready. */
export const useBoot = (): void => {
  useMount(() => {
    removeRootLoadingClass();
  });
};
