import * as TP from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateOrUpdateTablePreferenceMutationVariables = TP.Exact<{
  input: TP.TablePreferenceInput;
}>;


export type CreateOrUpdateTablePreferenceMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createOrUpdateTablePreference: (
    { readonly __typename?: 'TablePreferenceResponseType' }
    & Pick<TP.TablePreferenceResponseType, '_id' | 'tableName' | 'renderType' | 'createdAt' | 'lastModified' | 'createdBy' | 'objectId'>
    & { readonly tableFields: ReadonlyArray<(
      { readonly __typename?: 'TableFields' }
      & Pick<TP.TableFields, 'name' | 'id' | 'isVisible' | 'sort' | 'width'>
    )> }
  ) }
);


export const CreateOrUpdateTablePreferenceDocument = gql`
    mutation CreateOrUpdateTablePreference($input: TablePreferenceInput!) {
  createOrUpdateTablePreference(input: $input) {
    _id
    tableName
    renderType
    tableFields {
      name
      id
      isVisible
      sort
      width
    }
    createdAt
    lastModified
    createdBy
    objectId
  }
}
    `;
export type CreateOrUpdateTablePreferenceMutationFn = Apollo.MutationFunction<CreateOrUpdateTablePreferenceMutation, CreateOrUpdateTablePreferenceMutationVariables>;

/**
 * __useCreateOrUpdateTablePreferenceMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateTablePreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateTablePreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateTablePreferenceMutation, { data, loading, error }] = useCreateOrUpdateTablePreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateTablePreferenceMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateTablePreferenceMutation, CreateOrUpdateTablePreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateTablePreferenceMutation, CreateOrUpdateTablePreferenceMutationVariables>(CreateOrUpdateTablePreferenceDocument, options);
      }
export type CreateOrUpdateTablePreferenceMutationHookResult = ReturnType<typeof useCreateOrUpdateTablePreferenceMutation>;
export type CreateOrUpdateTablePreferenceMutationResult = Apollo.MutationResult<CreateOrUpdateTablePreferenceMutation>;
export type CreateOrUpdateTablePreferenceMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateTablePreferenceMutation, CreateOrUpdateTablePreferenceMutationVariables>;