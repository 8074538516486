import { getLocalStorage } from '@utils/localStorage';
import { KeycloakProfile } from 'keycloak-js';
import { createContext, useContext } from 'react';

interface OrgItem {
  id: string;
  hierarchyCode: string;
}

export type MMKeycloakProfile = KeycloakProfile & {
  orgs?: OrgItem[];
  attributes?: Record<string, anyOk>;
};

// ts-unused-exports:disable-next-line
export interface AuthState {
  status: AuthStatus;
  user: MMKeycloakProfile | null;
  ldIdentified: boolean;
}

export interface UserData {
  email?: string;
  name?: string;
  key?: string;
  hostname: string;
}

export const LOCAL_STORAGE_LD_USER_IDENTIFIERS = 'mastery-ld-user-identifiers';

export const getUserData = (): UserData | undefined => {
  return getLocalStorage(LOCAL_STORAGE_LD_USER_IDENTIFIERS) ?? undefined;
};

export const userDataFromStorage = getLocalStorage<UserData>(
  LOCAL_STORAGE_LD_USER_IDENTIFIERS
);

export const STARTED_WITH_USER_DATA = Boolean(userDataFromStorage);

export enum AuthStatus {
  unitialized,
  loading,
  authenticated,
  errored,
}

export const defaultContext: AuthState = {
  status: AuthStatus.unitialized,
  user: null,
  ldIdentified: STARTED_WITH_USER_DATA,
};

export const AuthContext = createContext<AuthState>(defaultContext);
export const useKeycloakAuthContext = (): AuthState => useContext(AuthContext);
