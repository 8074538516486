import { DatePicker as SpectrumDatePicker } from '@adobe/react-spectrum';
import { DateFieldRange } from '@components/DateRangePicker/DateFieldRange';
import { DateType } from '@components/DateRangePicker/defs';
import {
  RelativeString,
  RelativeUnitForDatepickerV2,
  relativeNamedMap,
  relativeStringToObj,
  relativeToString,
  singleDateToRelativeString,
} from '@components/DateRangePicker/util/relative';
import { Global } from '@emotion/react';
import { useFlagMe276185DatepickerDefaultMinMaxValues } from '@generated/flags/ME-276185-datepicker-default-min-max-values';
import { useFlagMe326512DatePickerDismissOnShortcutKey } from '@generated/flags/ME-326512-date-picker-dismiss-on-shortcut-key';
import { useFlagMe329571TableColumnFilterHidden } from '@generated/flags/ME-329571-table-column-filter-hidden';
import { useEffectAfterMount } from '@hooks/useEffectAfterMount';
import { useFullstoryElement } from '@hooks/useFullstory';
import { useIncrease } from '@hooks/useIncrease';
import { useTheme } from '@hooks/useTheme';
import { ZonedDateTime, now, parseAbsolute } from '@internationalized/date';
import { DateValue } from '@react-types/datepicker';
import {
  FullStoryElementType,
  FullStoryElements,
  FullStoryTypes,
} from '@utils/fullstory';
import { useWorkflow } from '@utils/fullstory/context';
import {
  IANATimezones,
  currentTZ,
  isDateWithinDefaultDatePickerRange,
  parseStringDate,
  shiftDateByTimezone,
} from '@utils/time';
import { startOfDay } from '@utils/time/util';
import cx from 'classnames';
import { addYears, isValid } from 'date-fns';
import { isDate, isEqual, noop, omit } from 'lodash-es';
import {
  FC,
  FocusEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocale } from 'react-aria';
import { useMount } from 'react-use';
import { attemptVisualCalendarForcedUpdate } from '../DateRangePicker/';
import flagOutline from '../DateRangePicker/img/flagOutline.svg';
import flagOutlineActive from '../DateRangePicker/img/flagOutlineActive.svg';
import flagOutlineMuted from '../DateRangePicker/img/flagOutlineMuted.svg';
import plusMinus from '../DateRangePicker/img/plusMinus.svg';
import CalendarSVG from '../Icon/Calendar.svg';
import {
  COMPONENT_DATEPICKER,
  coerceDateToModernYear,
  dateNeedsModernYearCoercion,
} from './util';

interface FitBoundsProps {
  start?: DateType;
  timezone: IANATimezones;
  computedValue: {
    start?: ZonedDateTime;
    end?: ZonedDateTime;
    value?: ZonedDateTime;
  } | null;
  showYears?: boolean;
  isOpen?: boolean;
  key?: 'start' | 'value';
}

function todaysDateInNYears(n: number = 100): Date {
  return addYears(new Date(), n);
}

const fitBoundsSingleDatepicker = (
  args: FitBoundsProps
): Pick<FitBoundsProps, 'start'> => {
  const { start, timezone, showYears, isOpen } = args;
  let startVal = start;

  if (!startVal) {
    return { start: startVal };
  }

  // const noopDate = parseAbsolute(new Date(1).toISOString(), 'UTC');
  // If the user picked from the calendar, (did not use the input with keyboard), we opt out of most coercions because they have chosen a very specific date by paging through the months.
  const pickedFromCalendar = isOpen;
  const cutoffNegative = now(timezone).subtract({ months: 3, days: 1 });
  const cutoffPositive = cutoffNegative.add({ years: 1 });

  if (!showYears && !pickedFromCalendar) {
    if (startVal.compare(cutoffNegative) < 0) {
      startVal = startVal.add({ years: 1 });
    } else if (startVal.compare(cutoffPositive) > 0) {
      startVal = startVal.subtract({ years: 1 });
    }
    return { start: startVal };
  }
  return { start: startVal };
};

export interface OnChangeArg {
  value: Date | null;
  relative: RelativeString | null;
}

interface InternalState {
  value: Date | null;
  minus: number;
  plus: number;
  relativeBase: RelativeUnitForDatepickerV2 | null;
}

/** Props from DPV1 are props iniitally are used directly on DPV1 and have an effect on the logic
 *  we passed these into DPV2 to handle the functionality of DPV1 in the best way possible to migrate to DPV2 effectively
 */
interface PropsFromDPV1 {
  nonClearable?: boolean;
}

interface Props extends PropsFromDPV1 {
  label?: string;
  defaultOpen?: boolean;
  onChange: (kwargs: OnChangeArg) => void;
  value: Maybe<Date>;
  timezone: IANATimezones;
  /** Show years on the picker. This prop should not be used per product decision, unless the user needs 6+ month time ranges, ie accounting. Defaults to false. */
  showYears?: boolean;
  /** Specify the exact relative options to show. If this prop not defined, all options will show */
  relativeOptionsEnabled?: RelativeUnitForDatepickerV2[];
  relative: RelativeString | null;
  onOpenChange?: (isOpen: boolean) => void;
  /** Put the input inside of the dialog. Probably only used for chip filter implementations. */
  inlineInput?: boolean;
  readOnly?: boolean;
  hideRelativeOptions?: boolean;
  onBlur?: (event: FocusEvent) => void;
  onFocus?: (event: FocusEvent) => void;
  ['data-testid']?: string | null;
  minValue?: Date | undefined | null;
  maxValue?: Date | undefined | null;
  id?: string | null;
  isDisabled?: boolean;
  autoFocus?: boolean;
  isRequired?: boolean;
  isDateUnavailable?: (date: DateValue) => boolean;
  name?: string;
  fsSearchElement?: FullStoryElementType;
  fsParent?: string;
  fsName?: string;
  fsType?: FullStoryTypes;
  fsElement?: FullStoryElementType;
  ['aria-invalid']?: boolean;
}

const keyboardMapping: Record<string, RelativeUnitForDatepickerV2> = {
  t: 'T',
  m: 'M',
  y: 'Y',
};

// Locales following MM/DD/YYYY format
export const enUSLocaleFormat: Set<string> = new Set([
  'en-US', // United States
  'en-CA', // Canada
  'en-PR', // Puerto Rico
  'en-PH', // Philippines
  'fr-CA', // Canada
]);

const getKeyboardFromRelative = (
  base: RelativeUnitForDatepickerV2 | null
): string | undefined => {
  return Object.entries(keyboardMapping).find(([, s]) => s === base)?.[0];
};

const coerceToInternalStateFromProps = (props: Props): InternalState => {
  const { value, relative, timezone } = props;
  const stringStart = isDate(value) ? value?.toISOString() : value;
  let coercedStart = stringStart;
  let coercedRelativeBase = null;
  const relativeObj = relativeStringToObj(relative as unknown as string);
  if (relative && relativeObj) {
    const map =
      relativeNamedMap[
        (relativeObj?.base ?? '') as RelativeUnitForDatepickerV2
      ];
    coercedStart = map
      .startFn(timezone ?? currentTZ)
      .add({ days: relativeObj?.start.days ?? 0 })
      .toDate()
      .toISOString();
    coercedRelativeBase =
      (relativeObj?.base as RelativeUnitForDatepickerV2) ?? null;
  } else if (coercedStart) {
    coercedRelativeBase =
      singleDateToRelativeString(new Date(coercedStart)) ?? null;
  }
  return {
    value: coercedStart ? new Date(coercedStart) : null,
    minus: Math.abs(relativeObj?.start.days ?? 0),
    plus: relativeObj?.end.days ?? 0,
    relativeBase: coercedRelativeBase,
  };
};

const areInternalStatesEqual = (
  state1: InternalState,
  state2: InternalState
): boolean => {
  return (
    state1.value?.valueOf() === state2.value?.valueOf() &&
    state1.minus === state2.minus &&
    state1.plus === state2.plus &&
    state1.relativeBase === state2.relativeBase
  );
};

const coerceToStartOfDay = (d: Maybe<ZonedDateTime>): ZonedDateTime | null => {
  if (!d) {
    return null;
  }
  return startOfDay(d);
};

const relativeOptionsEnabledDefault = [
  'T',
  'M',
  'Y',
] as RelativeUnitForDatepickerV2[];

export const DatePickerVersion2: FC<Props> = (props) => {
  const {
    defaultOpen = false,
    onChange: onChangeProp,
    value,
    timezone,
    showYears: showYearsProp = false,
    relative = '',
    inlineInput = false,
    hideRelativeOptions = false,
    relativeOptionsEnabled = relativeOptionsEnabledDefault,
    readOnly,
    onOpenChange,
    minValue = undefined,
    maxValue = undefined,
    isDisabled,
    name,
    nonClearable,
    fsSearchElement,
    fsName,
    fsType = 'date-picker',
    fsParent,
    fsElement = FullStoryElements.FIELD_INPUT,
    ...spreadProps
  } = props;
  const preventBadDateInputs = useFlagMe276185DatepickerDefaultMinMaxValues();
  const closeOnShortcutKey = useFlagMe326512DatePickerDismissOnShortcutKey();
  const useOverFlowHiddenOnDatepickerColumnFilter =
    useFlagMe329571TableColumnFilterHidden();
  const { locale } = useLocale();
  const isLocaleEnUSFormat = enUSLocaleFormat.has(locale);
  const [internalIsOpen, setInternalIsOpen] = useState(defaultOpen ?? false);
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [showYears, setShowYears] = useState(showYearsProp);
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [key, incKey] = useIncrease();
  const { colors, gray, formElement, fonts } = useTheme();
  const [internalState, setInternalState] = useState<InternalState>(
    coerceToInternalStateFromProps(props)
  );

  const onChange = (arg: OnChangeArg): void => {
    let val = arg.value;
    if (!val && nonClearable) {
      val = new Date();
    }
    onChangeProp({
      value: val,
      relative: arg.relative,
    });
  };

  useEffect(() => {
    const coerced = coerceToInternalStateFromProps(props);

    // If both relative and value are provided, use the relative. Coerced has the correct calculated date.
    if (relative && value && !isEqual(value, coerced.value)) {
      onChange({ value: coerced.value, relative });
    }

    if (!areInternalStatesEqual(internalState, coerced)) {
      setInternalState(() => coerced);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, relative]);

  function defaultDateLimitsCheck(): boolean {
    const coerced = coerceToInternalStateFromProps(props);
    if (isValid(coerced.value) && preventBadDateInputs) {
      const newValueWithinMaxDateValue = isDateWithinDefaultDatePickerRange(
        coerced?.value,
        todaysDateInNYears(-100),
        todaysDateInNYears(100)
      );
      if (!newValueWithinMaxDateValue) {
        setInternalState(() => ({
          value: null,
          plus: 0,
          minus: 0,
          relativeBase: null,
        }));
        onChange({
          value: null,
          relative: null,
        });
        return false;
      }
    }
    return true;
  }

  useMount(() => {
    // only run when mounting to make sure the date provided into props is within +/- 100 years
    defaultDateLimitsCheck();
    // https://masterysys.atlassian.net/browse/ME-339811
    /*
    future work: we need to add some time onChange to pass an adjusted time if a consumer passes a relative value
    see ticket for more details
    this problem is handled for Chip Filters but not handled in the DatepickerV2 raw component
    */
  });

  const computedValue = internalState?.value
    ? {
        value: parseAbsolute(internalState.value.toISOString(), timezone),
      }
    : null;

  const setRelative = useCallback(
    (key: RelativeUnitForDatepickerV2): void => {
      const obj = relativeNamedMap[key];
      setInternalState((state) => {
        const newValue = obj
          .startFn(timezone ?? currentTZ)
          .subtract({ days: internalState.minus })
          .toDate();

        const isNeg = (state.value?.valueOf() ?? 0) > (newValue.valueOf() ?? 0);
        attemptVisualCalendarForcedUpdate({ isNeg, incKey });
        return {
          value: newValue,
          relativeBase: key as unknown as RelativeUnitForDatepickerV2,
          minus: state.minus,
          plus: state.plus,
        };
      });
    },
    [incKey, internalState, timezone]
  );

  useEffectAfterMount(() => {
    const computed: OnChangeArg = {
      value: internalState?.value ?? null,
      relative: internalState.relativeBase
        ? relativeToString({
            base: internalState.relativeBase,
            start: { days: internalState.minus * -1 },
            end: { days: internalState.plus },
          }) ?? null
        : null,
    };
    onChange(computed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalState?.relativeBase]);

  const foundKeyboardRelativeMatch = getKeyboardFromRelative(
    internalState.relativeBase
  );

  const hasPlusOrMinus =
    internalState.relativeBase && (internalState.minus || internalState.plus);

  const computedMinValue = minValue
    ? parseAbsolute(minValue?.toISOString(), timezone)
    : undefined;
  const computedMaxValue = maxValue
    ? parseAbsolute(maxValue?.toISOString(), timezone)
    : undefined;

  const borderStyle =
    nonClearable && internalState.value === null
      ? `1px solid ${colors?.error} !important`
      : `1px solid ${gray[80]}`;
  const borderColor =
    (nonClearable && internalState?.value === null) || props['aria-invalid']
      ? colors?.error
      : gray[80];

  const internalOnChange = (
    rawValue: anyOk,
    opts?: {
      skipFitBounds?: boolean;
    }
  ): anyOk => {
    let value = rawValue as ZonedDateTime | null;
    if (value == null) {
      setInternalState(() => ({
        value: null,
        plus: 0,
        minus: 0,
        relativeBase: null,
      }));
      return;
    }
    if (!value?.timeZone) {
      // this condition happens when the user first picks a date when the value started as null
      // in this case, we need to take that start of day that was assumed to be in the user's TZ and convert it.
      value = parseAbsolute(
        shiftDateByTimezone(value.toDate(), currentTZ, timezone).toISOString(),
        timezone
      );
    }
    let start: ZonedDateTime | undefined = value;
    if (!opts?.skipFitBounds) {
      start = fitBoundsSingleDatepicker({
        start: value,
        timezone,
        computedValue,
        showYears,
        isOpen: internalIsOpen,
        key: 'value',
      }).start;
    }
    const newVal = coerceToStartOfDay(start)?.toDate() ?? null;
    setInternalState((s) => ({
      value: newVal ?? s.value,
      plus: 0,
      minus: 0,
      relativeBase: null,
    }));
    onChange({
      value: newVal,
      relative: null,
    });
  };

  const { getFsComponentProps } = useFullstoryElement();
  const { id } = useWorkflow();
  return (
    <>
      <div
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        name={name ?? ''}
        id={props.id ?? undefined}
        key={key}
        data-datepickerv2
        data-show-years={showYears || undefined}
        data-timezone={timezone}
        data-component={COMPONENT_DATEPICKER}
        aria-invalid={props['aria-invalid'] || undefined}
        {...getFsComponentProps({
          name: fsName ?? name,
          parent: fsParent ?? id,
          element: fsElement,
          type: fsType,
        })}
        onPaste={(e) => {
          e.preventDefault();
          const value = e.clipboardData.getData('text/plain').trim();
          const parsed = parseStringDate(value);
          if (!parsed) {
            return;
          }
          const now = new Date();
          const yearIsDiff = parsed.getFullYear() !== now.getFullYear();
          if (yearIsDiff) {
            setShowYears(true);
          }
          const zoned = parseAbsolute(
            shiftDateByTimezone(parsed, currentTZ, timezone).toISOString(),
            timezone
          );
          internalOnChange(zoned, { skipFitBounds: yearIsDiff });
        }}
        css={{
          ...(inlineInput
            ? {
                height: 0,
                width: 0,
                lineHeight: 0,
                overflow: 'hidden',
                position: 'relative',
                top: -32,
              }
            : {
                height: 32,
              }),
          '[data-testid="year"]': {
            display: showYears ? 'block' : 'none',
          },
          // modify the show/hide the '/' based on MM/DD/YYYY or DD/MM/YYYY
          '[data-testid="year"] + span': {
            display: showYears
              ? 'block'
              : isLocaleEnUSFormat
              ? 'none'
              : 'block',
          },
          // modify the show/hide the '/' based on MM/DD/YYYY or DD/MM/YYYY
          '[data-testid="day"] + span': {
            display: showYears
              ? 'block'
              : isLocaleEnUSFormat
              ? 'none'
              : 'block',
          },
          // modify the show/hide the '/' based on MM/DD/YYYY or DD/MM/YYYY
          '[data-testid="month"] + span': {
            display: showYears
              ? 'block'
              : isLocaleEnUSFormat
              ? 'block'
              : 'none',
          },
          '[class*="spectrum-InputGroup"]': {
            borderColor: `${borderColor} !important`,
            width: '100% !important',
          },
          '[class*=react-spectrum-Datepicker-inputSized]': {
            minWidth: 'unset !important',
            paddingLeft: 3,
          },
          '[class*="spectrum-FieldButton"]::before': {
            borderColor: `${borderColor} !important`,
            borderLeftWidth: '0px !important',
          },
          '[class*="react-spectrum-Datepicker-startField"]::before': {
            content: `'${foundKeyboardRelativeMatch}'`,
            display: foundKeyboardRelativeMatch ? 'block' : 'none',
            color: gray[30],
            paddingLeft: 5,
            paddingRight: hasPlusOrMinus ? 12 : 0,
            textTransform: 'uppercase',
            height: 18,
            fontSize: 12,
            width: 20,
            backgroundImage: `url("${flagOutline}")`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            top: 1,
          },
          '[class*="react-spectrum-Datepicker-fieldWrapper"]': inlineInput
            ? {
                width: '1px !important',
                minWidth: '0 !important',
                height: 1,
                opacity: 0,
                pointerEvents: 'none',
                overflow: 'hidden',
              }
            : {
                minWidth: 'unset !important',
                width: '100% !important',
              },
          '[class*="react-spectrum-Datepicker-fieldWrapper"]#date-field-inline-input':
            {
              width: 'auto !important',
              minWidth: 'auto !important',
              height: 'auto',
              opacity: 1,
              pointerEvents: 'auto',
            },
          '[class*="react-spectrum-Datepicker-startField"]::after': {
            content: `''`,
            display: hasPlusOrMinus ? 'block' : 'none',
            height: 14,
            position: 'absolute',
            width: 14,
            left: 34,
            top: 10,
            backgroundImage: `url("${plusMinus}")`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          },
          '[class*="spectrum-Textfield-input"]': {
            paddingRight: '2px !important',
            paddingLeft: '0px !important',
            paddingTop: '6px !important',
            overflowX: 'hidden',
            fontSize: '12px !important',
          },
          '[class*="react-spectrum-Datepicker-inputContents"]': {
            minWidth: 'fit-content !important',
          },
          '[class*=spectrum-Textfield-input]': {
            background: readOnly ? formElement.readOnly.background : undefined,
          },
          '[class*=spectrum-Icon]': {
            backgroundImage: `url("${CalendarSVG}")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          },
          '[class*=spectrum-Icon] > *': {
            display: 'none',
          },
        }}
        onKeyUp={(e): void => {
          if (e.key === 'q') {
            return setShowYears((t) => !t);
          }
          const match = keyboardMapping[e.key.toLowerCase()];
          if (match) {
            setRelative(match);
            (
              document.querySelector(
                `[data-relative=${match}]`
              ) as Maybe<HTMLButtonElement>
            )?.focus();

            // If user types the shortcut, dismiss the dialog.
            if (closeOnShortcutKey) {
              setInternalIsOpen(false);
              onOpenChange?.(false);
            }
          }
        }}
        data-testid={
          props?.['data-testid']
            ? `datepicker-input-${props['data-testid']}`
            : 'datepicker-input'
        }
      >
        <input
          type="hidden"
          required={props?.isRequired}
          disabled={isDisabled}
          data-datepicker-hidden-input
          data-datepicker-years={showYears || undefined}
          value={
            computedValue?.value
              ? `${computedValue.value.month
                  .toString()
                  .padStart(2, '0')}/${computedValue.value.day
                  .toString()
                  .padStart(2, '0')}${
                  showYears ? '/' + computedValue.value.year : ''
                }`
              : ''
          }
        />
        <Global
          styles={{
            '[class*="react-spectrum-Datepicker-dialogContent"]': {
              display: 'grid !important',
              gridTemplateColumns: 'initial',
            },
            '[class*=spectrum-Dialog][class*=react-spectrum-Datepicker-dialog]':
              {
                minWidth: 'unset !important',
              },
            '[class*=react-spectrum-Datepicker-dialog] [class*=spectrum-Dialog-grid]':
              {
                padding: 12,
                display: 'block !important',
              },
            '[class*="spectrum-Textfield-input"]': {
              border: `1px solid ${borderColor} !important`,
            },
            '[class*="react-spectrum-Datepicker-dialogContent"]:has([data-relative-selection])':
              {
                display: 'grid !important',
                gridTemplateColumns: 'min-content 1fr',
              },
            '[class*="spectrum-FieldButton"]': {
              alignItems: 'center',
              width: '24px !important',
              cursor: 'pointer',
            },
            '#date-field-inline-input [data-testid="year"]': {
              display: 'none',
            },
            '#date-field-inline-input [data-testid="day"] + span': {
              display: 'none',
            },
            '[class*=react-spectrum-Datepicker-timeFields]': {
              gridColumnStart: 2,
            },
            '[class*=spectrum-Popover]': {
              transition: 'none !important',
            },
            '[class*="spectrum-Icon"]': {
              paddingRight: '0 !important',
              height: '14px !important',
              width: '14px !important',
            },
            '[class*="spectrum-Calendar-months"]': {
              paddingBottom: 10,
            },
            '[class*=react-spectrum-Datepicker-calendar][class*=is-invalid]': {
              display: 'block !important',
            },
            '[class*=spectrum-InputGroup--invalid]': {
              '[aria-label="Calendar"] svg': {
                display: 'none',
              },
              '[class*="spectrum-UIIcon-AlertMedium"]': {
                right: '-19px !important',
                top: '8px !important',
                zIndex: 5,
                pointerEvents: 'none',
              },
            },
            '[class*="spectrum-Calendar"] [role=button]:focus-visible': {
              outline: '0 !important',
            },
            '[class*=react-spectrum-DatePicker-placeholder]': {
              fontFamily: `${fonts.main} !important`,
              fontStyle: 'normal !important',
            },
            [`[data-column-filter] > [data-component="${COMPONENT_DATEPICKER}"]`]:
              useOverFlowHiddenOnDatepickerColumnFilter
                ? { overflow: 'hidden' }
                : {},
          }}
        />
        <SpectrumDatePicker
          {...omit(spreadProps, ['label', 'name'])}
          isOpen={internalIsOpen}
          // without this noop, we get error text rendered below the control, which we don't want.
          errorMessage={noop}
          id={
            showYears
              ? cx('datepicker-years', props['id'] ?? null)
              : cx('dateonly-datepicker', props['id'] ?? null)
          }
          data-spectrum-datepicker
          shouldForceLeadingZeros
          // this labelledby is probably wrong, but we get extraneous warnings from react-spectrum without specifying it
          aria-labelledby={props.name ?? props.id ?? ''}
          onChange={internalOnChange}
          isDisabled={isDisabled ?? false}
          minValue={
            (computedMinValue as anyOk) ??
            parseAbsolute(todaysDateInNYears(-100).toISOString(), timezone)
          }
          maxValue={
            (computedMaxValue as anyOk) ??
            parseAbsolute(todaysDateInNYears(100).toISOString(), timezone)
          }
          autoFocus={props?.autoFocus ?? false}
          isDateUnavailable={(date): boolean => {
            if (props?.isDateUnavailable) {
              return props?.isDateUnavailable(date as anyOk);
            }
            return false;
          }}
          hourCycle={24}
          granularity="day"
          value={computedValue?.value ? computedValue.value : (null as anyOk)}
          onOpenChange={(isOpen: boolean): void => {
            setInternalIsOpen(isOpen);
            if (onOpenChange) {
              onOpenChange?.(isOpen);
            }
          }}
          onBlur={(e): void => {
            if (isValid(internalState.value)) {
              if (dateNeedsModernYearCoercion(internalState.value as Date)) {
                const newDate = coerceDateToModernYear(
                  internalState?.value as Date,
                  timezone
                );
                setInternalState((s) => ({
                  ...s,
                  value: newDate,
                }));
                onChange({
                  value: newDate,
                  relative: null,
                });
                return;
              }
            }
            const isOkBounds = defaultDateLimitsCheck();
            if (!isOkBounds) {
              // returning early because defaultDateLimitsCheck has side effects of onChange
              return;
            }
            onChange({
              relative:
                (internalState.relativeBase &&
                  relativeToString({
                    base: internalState.relativeBase,
                    start: {},
                    end: {},
                  })) ||
                null,
              value: internalState.value,
            });
            if (props?.onBlur) {
              props.onBlur(e);
            }
          }}
          onFocus={(e): void => {
            if (props?.onFocus) {
              props?.onFocus(e);
            }
          }}
          isReadOnly={readOnly}
          {...{
            extraDialogContent: (): ReactNode => (
              <>
                {inlineInput && (
                  <>
                    <div
                      css={{
                        gridColumn: 'span 2',
                        paddingBottom: 16,
                      }}
                    >
                      <DateFieldRange
                        fsSearchElement={fsSearchElement}
                        singleDate
                        value={computedValue?.value ?? (null as anyOk)}
                        onChange={(val): void => {
                          if (val === null) {
                            onChange({ value: null, relative: null });
                          }
                          const zonedInternalState = internalState?.value
                            ? parseAbsolute(
                                internalState.value?.toISOString(),
                                timezone
                              )
                            : null;

                          const newValue =
                            val.start ??
                            zonedInternalState ??
                            computedValue?.value;
                          const args = { start: newValue };
                          const { start } = fitBoundsSingleDatepicker({
                            ...args,
                            timezone,
                            computedValue,
                            showYears,
                            isOpen: internalIsOpen,
                            key: 'value',
                          });
                          if (val) {
                            const newVal =
                              coerceToStartOfDay(start)?.toDate() ?? null;
                            onChange({
                              value: newVal,
                              relative: null,
                            });
                          }

                          setInternalState((s) => ({
                            ...s,
                            value: start?.toDate() ?? s.value,
                            plus: 0,
                            minus: 0,
                            relativeBase: null,
                          }));
                        }}
                      />
                    </div>
                  </>
                )}
                {!hideRelativeOptions && (
                  <div
                    css={{
                      paddingRight: 10,
                      margin: '0 10px 0 0',
                      width: 130,
                      button: { textAlign: 'left', display: 'block' },
                      borderRight: borderStyle,
                    }}
                    // this helps this component work with chip filters. without this line, when user picks a relative option, the chip filter will close and the value will not be set
                    onMouseDown={(e): void => e.stopPropagation()}
                  >
                    <div css={{ display: 'grid' }} data-relative-selection>
                      {relativeOptionsEnabled.map((key) => {
                        const obj = relativeNamedMap[key];
                        const isActive = internalState.relativeBase === key;
                        return (
                          <button
                            key={key}
                            data-relative={key}
                            data-active={isActive ? 'true' : undefined}
                            type="button"
                            css={{
                              color: isActive ? colors.primary : undefined,
                              padding: '5px',
                              paddingLeft: 32,
                              position: 'relative',
                              borderRadius: 3,
                              whiteSpace: 'nowrap',
                              '&:hover': {
                                background: gray[95],
                              },
                              '&:before': {
                                content: `'${
                                  getKeyboardFromRelative(key) ?? ''
                                }'`,
                                display: 'block',
                                width: 18,
                                height: 18,
                                backgroundImage: `url("${
                                  isActive
                                    ? flagOutlineActive
                                    : flagOutlineMuted
                                }")`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                position: 'absolute',
                                left: 2,
                                top: 4,
                                paddingLeft: 5,
                                textTransform: 'uppercase',
                                fontSize: '.9em',
                                lineHeight: '18px',
                                // eslint-disable-next-line mastery/named-colors
                                color: isActive ? colors.primary : '#bbb',
                              },
                            }}
                            onClick={(): void => {
                              setRelative(key);
                              setInternalIsOpen(true);
                            }}
                          >
                            {obj?.label}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                )}
              </>
            ),
          }}
        />
      </div>
    </>
  );
};
