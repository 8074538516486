import { createContext, useContext, useMemo } from 'react';

interface WorkflowContextProps {
  id: string | undefined;
}

export const useWorkflow = (): WorkflowContextProps => {
  const context = useContext(WorkflowContext);
  if (!context) {
    throw new Error(
      'useWorkflowContext must be used within a WorkflowProvider'
    );
  }
  return context;
};

// WorkflowContext
const WorkflowContext = createContext<WorkflowContextProps>({
  id: undefined,
});

import { ReactNode } from 'react';

interface WorkflowProps {
  id: string | undefined;
  children: ReactNode;
}

export const Workflow = ({ id, children }: WorkflowProps): JSX.Element => {
  const value = useMemo(() => ({ id }), [id]);
  return (
    <WorkflowContext.Provider value={value}>
      {children}
    </WorkflowContext.Provider>
  );
};
