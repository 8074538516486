import { LoadV2LabelDisplay } from '@app/Masterfind';
import { chatAtom } from '@app/layout/ChatButton';
import { Button } from '@components/Button';
import { SpeechButton } from '@components/Button/SpeechButton';
import { Icon } from '@components/Icon';
import { Loading } from '@components/Loading';
import { Pill } from '@components/Pill';
import { useShortcut } from '@components/Shortcut';
import { Spacer } from '@components/Spacer';
import { useFullstoryElement } from '@hooks/useFullstory';
import { useTheme } from '@hooks/useTheme';
import { HEADER_HEIGHT, IS_MERCATOR } from '@utils/constants';
import { FullStoryElements } from '@utils/fullstory';
import { useAtom } from 'jotai';
import { isArray } from 'lodash-es';
import Markdown from 'markdown-to-jsx';
import { FC, FormEvent, useContext, useEffect, useRef } from 'react';
import { useInterval } from 'react-use';
import { ChatContext } from './Provider';

interface Message {
  role: 'user' | 'assistant' | 'tool';
  type?: 'listening';
  data?: anyOk;
  content: string;
}

interface ChatContext {
  messages: Message[];
  inputValue?: string;
}

const LOADING_CONTENT = 'Loading...';

const inputHeight = 64;
const fontSize = 16;

const botMessageStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  textAlign: 'left',
  width: '100%',
};

export const Chat: FC = () => {
  const { send, state } = useContext(ChatContext);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [chatAtomState, setChatAtomState] = useAtom(chatAtom);
  const { card, colors, gray } = useTheme();

  const { getFsComponentProps } = useFullstoryElement();

  const submitMessage = (event: FormEvent): void => {
    event.preventDefault();
    if (state.context.inputValue?.trim()) {
      send({
        type: 'SEND_MESSAGE',
        message: { role: 'user', content: state.context.inputValue ?? '' },
      });
    }
  };

  useShortcut({
    keys: 'esc',
    debounceMillis: 20,
    onEvent: () => {
      setChatAtomState({
        open: false,
      });
    },
  });

  const scrollChat = (): void => {
    setTimeout(() => {
      messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, 20);
  };

  useEffect(() => {
    if (state.value === 'loading') {
      scrollChat();
    }
  }, [state.value]);

  useEffect(() => {
    scrollChat();
  }, [state.context.messages, chatAtomState.open]);

  // force this component to re-render every 300ms no matter what
  // this is useful for the current message flow
  useInterval(() => {
    //
  }, 300);

  if (!chatAtomState.open && !IS_MERCATOR) {
    return null;
  }

  const messages = state.context.messages;

  const userMessageStyles = {
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
    width: '100%',
    color: card.background,
  };

  return (
    <div
      css={{
        borderRadius: 5,
        fontSize,
        lineHeight: 1.5,
        padding: 20,
        width: 500,
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        top: HEADER_HEIGHT,
        position: 'sticky',
        background: card.background,
        gridTemplateRows: '100%',
        zIndex: 1000,
      }}
    >
      <div
        css={{
          position: 'relative',
          height: '100%',
          width: '100%',
          display: 'grid',
          gridTemplateRows: '1fr min-content',
        }}
      >
        <div
          data-messages
          css={{
            width: '100%',
            overflow: 'auto',
            padding: '10px 16px 10px 0',
          }}
        >
          {messages.map((message: fixMe, index: number) => {
            const isUserMessage = message.role === 'user';
            const isToolMessage = message.role === 'tool';
            if (isToolMessage) {
              return null;
            }
            // const content =
            //   message?.content?.replace(
            //     /\[(doc\d)\]/g,
            //     `<a href="#msg-${index}-$1">[$1]</a>`
            //   ) || '';
            const content = message?.content?.replace(/\[(doc\d)\]/g, '') || '';
            return (
              <div
                key={index}
                css={
                  {
                    marginBottom: 10,
                    ...(message.role === 'user'
                      ? userMessageStyles
                      : botMessageStyles),
                  } as anyOk
                }
              >
                <div
                  css={{
                    display: 'grid',
                    gridTemplateColumns: isUserMessage
                      ? undefined
                      : 'min-content 1fr',
                    gap: 10,
                  }}
                >
                  {/* {message.role !== 'user' && <strong>{message.role}</strong>} */}
                  {message.role === 'assistant' && (
                    <Icon i="robot" size="md" color={gray[80]} />
                  )}
                  <div
                    data-message-bubble
                    css={{
                      width: 'fit-content',
                      maxWidth: 470,
                      whiteSpace: 'pre-wrap',
                      border: isUserMessage
                        ? `1px solid transparent`
                        : `1px solid ${gray[80]}`,
                      padding: '2px 8px',
                      borderRadius: 5,
                      background: isUserMessage
                        ? colors.primary
                        : card.background,
                    }}
                  >
                    {message.role === 'assistant' &&
                      content === LOADING_CONTENT && (
                        <div
                          css={{
                            transform: 'scale(0.4)',
                            margin: '-20px',
                          }}
                        >
                          <Loading delay={0} />
                        </div>
                      )}
                    {!(
                      message.role === 'assistant' &&
                      content === LOADING_CONTENT
                    ) && (
                      <>
                        <Markdown>{content}</Markdown>
                        <div css={{ paddingTop: 10 }}>
                          {message?.source &&
                            message?.source.length > 0 &&
                            message.source.map((item: fixMe, index: number) => {
                              return <div key={index}>{item}</div>;
                            })}
                        </div>
                      </>
                    )}
                    {isArray(message.data) &&
                      message.data[0]?.Id &&
                      message.data[0]?.LoadCode && (
                        <div>
                          <ul
                            css={{
                              listStyleType: 'none',
                              fontSize: '.8em',
                              lineHeight: 2,
                            }}
                          >
                            {(message.data as anyOk).map(
                              (item: anyOk, index: number) => {
                                return (
                                  <li key={index}>
                                    <a
                                      href={`/loads/${item.Id}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      css={{ whiteSpace: 'nowrap' }}
                                    >
                                      {item.LoadCode}&nbsp;
                                      <LoadV2LabelDisplay
                                        noPrefix
                                        css={{ display: 'inline-block' }}
                                        node={{
                                          loadCode: item.LoadCode,
                                          id: item.Id,
                                          originCity: item.OriginCity,
                                          originState: item.OriginState,
                                          destinationCity: item.DestinationCity,
                                          destinationState:
                                            item.DestinationState,
                                          customer: item.Customer,
                                        }}
                                        filterVal={item.LoadCode}
                                      />
                                    </a>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            );
          })}
          <div ref={messagesEndRef} id="msg-end" />
        </div>
        <div data-suggestions css={{ display: 'flex', gap: 10 }}>
          {['How to create a load?'].map((suggestion, index) => (
            <button
              key={index}
              onClick={(): void => {
                send({
                  type: 'SEND_MESSAGE',
                  message: {
                    role: 'user',
                    content: suggestion,
                  },
                });
              }}
              {...getFsComponentProps({
                name: 'chat-suggestion-button',
                element: FullStoryElements.BUTTON,
                parent: 'chat',
              })}
            >
              <Pill value={suggestion} />
            </button>
          ))}
        </div>
        <Spacer />
        <form
          onSubmit={submitMessage}
          ref={formRef}
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr min-content min-content',
            gap: 6,
          }}
        >
          <textarea
            placeholder="Type your message here.
(Shift + Enter for new line)"
            autoFocus
            ref={inputRef}
            // rows={1}
            data-testid="chat-input"
            css={{
              fontSize: 16,
              lineHeight: 1.5,
              height: inputHeight,
              minHeight: 'unset',
            }}
            onKeyDown={(e): void => {
              if (e.key === 'Enter' && !e.shiftKey) {
                send({
                  type: 'SEND_MESSAGE',
                  message: {
                    role: 'user',
                    content: e.currentTarget.value ?? '',
                  },
                });
              }
            }}
            onChange={(e): void => {
              let val = e.currentTarget.value;
              if (!val.trim()) {
                val = '';
              }
              send({
                type: 'INPUT_CHANGE',
                data: val,
              });
            }}
            value={state.context.inputValue ?? ''}
          />
          <SpeechButton
            onListenStart={(): void => {
              send({
                type: 'VOICE_INPUT_START',
              });
            }}
            onTranscriptUpdate={(transcript: string): void => {
              send({
                type: 'VOICE_INPUT_END',
                data: transcript,
              });
            }}
            css={{
              transition: 'none',
              '&:hover svg': {
                color: 'white !important',
              },
            }}
          />
          <Button
            type="submit"
            id="chat-send-button"
            css={{
              transition: 'none',
              '&:hover svg': {
                color: 'white !important',
              },
            }}
            name="chat-send-button"
          >
            <Icon
              i="paperPlane"
              color="primary"
              size="md"
              css={{
                transform: 'rotate(-10deg)',
              }}
            />
          </Button>
        </form>
      </div>
    </div>
  );
};
