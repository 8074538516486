/** Helps TS typings when using Promise.allSettled */
export const filterByPromiseFulfilled = <T extends unknown>(
  arr: PromiseSettledResult<T>[]
): PromiseFulfilledResult<T>[] => {
  return arr.filter(
    (res): res is PromiseFulfilledResult<T> => res.status === 'fulfilled'
  );
};

export const asyncDelay = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));
