import { createContext, ReactNode, useMemo } from 'react';

interface FieldContextProps {
  label: Maybe<string>;
  name: Maybe<string>;
}

export const FieldContext = createContext<FieldContextProps>({
  label: '',
  name: '',
});

interface Props {
  label: Maybe<string>;
  name: Maybe<string>;
  children: ReactNode;
}

export const FieldProvider = ({
  label,
  name,
  children,
}: Props): JSX.Element => {
  const value = useMemo(() => ({ label, name }), [label, name]);
  return (
    <FieldContext.Provider value={value}>{children}</FieldContext.Provider>
  );
};
