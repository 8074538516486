import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type LoadEmrEventPayloadFragment = (
  { readonly __typename?: 'LoadEMREvent' }
  & Pick<TP.LoadEmrEvent, 'id' | 'driverId'>
  & { loadEmrRouteId: TP.LoadEmrEvent['routeId'] }
  & { readonly loadEmrMessage: (
    { readonly __typename?: 'LoadEMRMessage' }
    & Pick<TP.LoadEmrMessage, 'type' | 'message'>
  ) }
);

export const LoadEmrEventPayloadFragmentDoc = gql`
    fragment LoadEMREventPayload on LoadEMREvent {
  id
  driverId
  loadEmrRouteId: routeId
  loadEmrMessage: message {
    type
    message
  }
}
    `;