import { submitMutation } from '@components/shared/mutation';
import { TabObj, TabsId } from '@components/Tabs';
import { useCreateOrUpdateTabPreferenceMutation } from '@generated/mutations/createOrUpdateTabPreference';
import {
  GetTabPreferenceDocument,
  GetTabPreferenceQuery,
  GetTabPreferenceQueryVariables,
} from '@generated/queries/getTabPreference';
import { useLazyQueryWithDataPromise } from '@hooks/useLazyQueryWithDataPromise';
import { compact } from 'lodash-es';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

interface TabPreferencesContextManager {
  getTabPreferencesById: (
    tabId: string,
    tabs: fixMe[],
    activeTab?: string | null
  ) => void;
  tabPreferences?: TabPreferencesMap;
  handleSaveTabPreferences: (
    tabId: string,
    tabs: Array<TabObj<string | TabsId>>,
    activeTabIndex?: string | null
  ) => Promise<{ isHidingActiveTab: boolean; tabs: Array<TabObj<string>> }>;
  error: boolean;
  loadingTabPreferences: boolean;
  handleSetTabPreferences: (
    id: string,
    tabs: Array<TabObj<string | TabsId>>
  ) => void;
  setTabPreferences: (
    tabPreferences: Record<string, Array<TabObj<string>>>
  ) => void;
}

const defaultSettings = {
  isVisible: true,
  default: false,
};

const TabPreferencesContext = createContext<TabPreferencesContextManager>({
  getTabPreferencesById: () => {},
  handleSaveTabPreferences: async () => ({
    isHidingActiveTab: false,
    tabs: [],
  }),
  tabPreferences: {},
  error: false,
  handleSetTabPreferences: () => {},
  setTabPreferences: () => {},
  loadingTabPreferences: false,
});

interface TabsResponseObj {
  id: string;
  default: boolean;
  isVisible: boolean;
  order: number;
  __typename?: 'Tab';
}

export function mergeTabsById(
  encodedTabs: Array<TabObj<string>>,
  savedTabPreferences: Omit<TabsResponseObj, '__typename'>[] | TabProperties[],
  options: { activeTab?: string | null } = {}
): Array<TabObj<string>> {
  // Using compact with the result of the map operation instead
  return compact(
    (encodedTabs || []).map((encodedTab) => {
      // Return null early if encodedTab is falsy or doesn't have an id
      if (!encodedTab || !encodedTab.id) {
        return null;
      }
      const tabFoundInSavedPreferences = savedTabPreferences.find(
        (item) => item.id === encodedTab.id
      );

      const baseSettings: TabObj<string> = {
        ...encodedTab,
        ...(tabFoundInSavedPreferences || defaultSettings),
      };

      // Override visibility only if it's active tab or locked
      if (
        options.activeTab?.toLocaleLowerCase() ===
          encodedTab.id?.toLocaleLowerCase() ||
        encodedTab.locked === true
      ) {
        return {
          ...baseSettings,
          isVisible: true,
        };
      }

      return baseSettings;
    })
  );
}

const mapTabPreferences = (
  tabs: ReadonlyArray<TabsResponseObj>
): Array<Omit<TabsResponseObj, '__typename'>> => {
  return tabs.map(({ id, order, default: isDefault, isVisible }) => ({
    id,
    order,
    default: isDefault,
    isVisible,
  }));
};

type TabProperties = Pick<
  TabObj<string>,
  'id' | 'default' | 'isVisible' | 'order' | 'locked' | 'disabled'
>;
export type TabArray = Array<TabProperties>;
// Map of tab group identifiers to their configurations
export type TabPreferencesMap = Record<string, TabArray>;

export const TabPreferencesProvider: FC<anyOk> = ({ children }) => {
  const [tabPreferences, setTabPreferences] = useState<TabPreferencesMap>({});
  const [error, setError] = useState<boolean>(false);
  const [loadingTabPreferences, setLoadingTabPreferences] =
    useState<boolean>(false);

  const getTabPreferenceById = useLazyQueryWithDataPromise<
    GetTabPreferenceQuery,
    GetTabPreferenceQueryVariables
  >(GetTabPreferenceDocument);

  const [handleCreateOrUpdateTabPreference] =
    useCreateOrUpdateTabPreferenceMutation();

  const getTabPreferencesById = useCallback(
    async (
      tabId: string,
      tabs: Array<TabObj<string>>,
      activeTab?: string | null
    ): Promise<void> => {
      try {
        setLoadingTabPreferences(true);
        const savedTabPreferences = await getTabPreferenceById({
          variables: {
            tabGroup: {
              tabGroupId: tabId,
            },
          },
        });
        const responsedTabs = savedTabPreferences.data.getTabPreference?.filter(
          (item) => item?.tabGroupId === tabId
        );
        const mergedTabs = mergeTabsById(
          tabs,
          [...(responsedTabs?.[0]?.tabs || [])],
          { activeTab: activeTab }
        );
        setTabPreferences((prefs) => ({
          ...prefs,
          [tabId]: mergedTabs,
        }));
        setError(false);
        setLoadingTabPreferences(false);
      } catch (error) {
        const encodedFallbackTabs = mergeTabsById(tabs, [], {
          activeTab: activeTab,
        });
        setTabPreferences((prefs) => ({
          ...prefs,
          [tabId]: encodedFallbackTabs,
        }));
        setError(true);
        setLoadingTabPreferences(false);
      }
    },
    [getTabPreferenceById]
  );

  function handleSetTabPreferences(id: string, updatedTabs: fixMe): void {
    setTabPreferences((prefs) => ({
      ...prefs,
      [id]: updatedTabs,
    }));
  }

  const handleSaveTabPreferences = useCallback(
    async (
      tabId: string,
      tabs: Array<TabObj<string>>,
      activeTabId?: string | null
    ): Promise<{ isHidingActiveTab: boolean; tabs: Array<TabObj<string>> }> => {
      const encodedTabs = tabs.map((tab, idx) => ({
        id: tab.id,
        default: tab.default ?? false,
        isVisible: tab?.locked || tab?.default ? true : tab.isVisible ?? true,
        order: tab.order ?? idx,
      }));

      // if a user is currently going "hide" a tab they current selected, we need to know that, and programmatically move the user to a new tab
      const isHidingActiveTab =
        activeTabId &&
        encodedTabs.find(
          (tab) =>
            tab.id?.toLocaleLowerCase() === activeTabId?.toLocaleLowerCase()
        )?.isVisible === false;

      const response = await submitMutation(() =>
        handleCreateOrUpdateTabPreference({
          variables: {
            input: {
              tabGroupId: tabId,
              tabs: encodedTabs,
            },
          },
        })
      );
      if (response?.data?.createOrUpdateTabPreference?.tabGroupId) {
        const tabPrefs = mapTabPreferences(
          response.data.createOrUpdateTabPreference.tabs
        );
        const mergedTabs = mergeTabsById(
          tabPreferences[`${tabId}`] as fixMe,
          tabPrefs
        );
        setTabPreferences((prefs: Record<string, TabArray>) => ({
          ...prefs,
          [tabId]: mergedTabs,
        }));
        return {
          isHidingActiveTab: isHidingActiveTab ? true : false,
          tabs: mergedTabs,
        };
      } else {
        setTabPreferences((prefs: Record<string, TabArray>) => ({
          ...prefs,
          [tabId]: tabs,
        }));
      }
      return {
        isHidingActiveTab: false,
        tabs: tabs,
      };
    },
    [handleCreateOrUpdateTabPreference, tabPreferences]
  );

  const value: TabPreferencesContextManager = useMemo(() => {
    return {
      getTabPreferencesById,
      tabPreferences,
      handleSaveTabPreferences,
      handleSetTabPreferences,
      setTabPreferences,
      error,
      loadingTabPreferences,
    };
  }, [
    getTabPreferencesById,
    tabPreferences,
    handleSaveTabPreferences,
    error,
    loadingTabPreferences,
  ]);

  return (
    <TabPreferencesContext.Provider value={value}>
      {children}
    </TabPreferencesContext.Provider>
  );
};

export const useTabPreferences = (): TabPreferencesContextManager => {
  const context = useContext(TabPreferencesContext);
  if (!context) {
    throw new Error(
      'useTabPreferences must be used within a TabPreferencesProvider'
    );
  }
  return context;
};
