import * as TP from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTabPreferenceQueryVariables = TP.Exact<{
  tabGroup: TP.TabPreferenceGroupInput;
}>;


export type GetTabPreferenceQuery = (
  { readonly __typename?: 'Query' }
  & { readonly getTabPreference: ReadonlyArray<(
    { readonly __typename?: 'TabPreferenceResponseType' }
    & Pick<TP.TabPreferenceResponseType, 'createdAt' | 'createdBy' | 'updatedAt' | 'tabGroupId'>
    & { readonly tabs: ReadonlyArray<(
      { readonly __typename?: 'Tab' }
      & Pick<TP.Tab, 'id' | 'default' | 'isVisible' | 'order'>
    )> }
  )> }
);


export const GetTabPreferenceDocument = gql`
    query GetTabPreference($tabGroup: TabPreferenceGroupInput!) {
  getTabPreference(tabGroup: $tabGroup) {
    createdAt
    createdBy
    updatedAt
    tabGroupId
    tabs {
      id
      default
      isVisible
      order
    }
  }
}
    `;

/**
 * __useGetTabPreferenceQuery__
 *
 * To run a query within a React component, call `useGetTabPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTabPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTabPreferenceQuery({
 *   variables: {
 *      tabGroup: // value for 'tabGroup'
 *   },
 * });
 */
export function useGetTabPreferenceQuery(baseOptions: Apollo.QueryHookOptions<GetTabPreferenceQuery, GetTabPreferenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTabPreferenceQuery, GetTabPreferenceQueryVariables>(GetTabPreferenceDocument, options);
      }
export function useGetTabPreferenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTabPreferenceQuery, GetTabPreferenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTabPreferenceQuery, GetTabPreferenceQueryVariables>(GetTabPreferenceDocument, options);
        }
export type GetTabPreferenceQueryHookResult = ReturnType<typeof useGetTabPreferenceQuery>;
export type GetTabPreferenceLazyQueryHookResult = ReturnType<typeof useGetTabPreferenceLazyQuery>;
export type GetTabPreferenceQueryResult = Apollo.QueryResult<GetTabPreferenceQuery, GetTabPreferenceQueryVariables>;