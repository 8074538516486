import { cloneDeep } from 'lodash-es';
import { CheckboxTreeProps as Props } from './checkboxTree';

class CheckboxTreeError extends Error {
  constructor(message: string) {
    super(message); // Pass the message to the base Error class
    this.name = 'CheckboxTreeError'; // Set the custom error name
    Object.setPrototypeOf(this, CheckboxTreeError.prototype); // Ensure the prototype chain is correct
  }
}

export enum CheckModel {
  ALL = 'all',
  PARENT = 'parent',
  LEAF = 'leaf',
}

export type CheckModelType = (typeof CheckModel)[keyof typeof CheckModel];

export class NodeModel {
  props: Props;
  flatNodes: Record<string, fixMe>;

  constructor(props: Props, nodes = {}) {
    this.props = props;
    this.flatNodes = nodes;
  }

  setProps(props: Props): void {
    this.props = props;
  }

  clone(): NodeModel {
    const clonedNodes = cloneDeep(this.flatNodes);

    return new NodeModel(this.props, clonedNodes);
  }

  getNode(value: string): fixMe {
    return this.flatNodes[value];
  }

  reset(): void {
    this.flatNodes = {};
  }

  flattenNodes(nodes: fixMe, parent?: fixMe, depth: number = 0): void {
    if (!Array.isArray(nodes) || nodes.length === 0) {
      return;
    }

    const { disabled, noCascade } = this.props;

    // Flatten the `node` property for internal lookups
    nodes.forEach((node, index) => {
      const isParent = this.nodeHasChildren(node);

      // Protect against duplicate node values
      if (this.flatNodes[node?.value] !== undefined) {
        throw new CheckboxTreeError(
          `Duplicate value '${node?.value}' detected. All node values must be unique.`
        );
      }

      this.flatNodes[node?.value] = {
        label: node?.label,
        value: node?.value,
        children: node?.children,
        parent,
        isChild: Boolean(parent?.value),
        isParent,
        isLeaf: !isParent,
        showCheckbox:
          node?.showCheckbox !== undefined ? node?.showCheckbox : true,
        disabled: this.getDisabledState(node, parent, disabled, noCascade),
        treeDepth: depth,
        index,
      };
      this.flattenNodes(node?.children, node, depth + 1);
    });
  }

  nodeHasChildren(node: fixMe): boolean {
    return Array.isArray(node?.children);
  }

  getDisabledState(
    node: fixMe,
    parent: fixMe,
    disabledProp: boolean,
    noCascade: boolean
  ): boolean {
    const { skipParentDisableCascade } = this.props;
    if (disabledProp) {
      return true;
    }

    if (!skipParentDisableCascade && !noCascade && parent?.disabled) {
      return true;
    }

    return Boolean(node?.disabled);
  }

  deserializeLists(lists: Record<string, anyOk>): void {
    const listKeys = ['checked', 'expanded'];

    // Reset values to false
    Object.keys(this.flatNodes).forEach((value) => {
      listKeys.forEach((listKey) => {
        this.flatNodes[value][listKey] = false;
      });
    });

    // Deserialize values and set their nodes to true
    listKeys.forEach((listKey) => {
      lists[listKey].forEach((value: string) => {
        if (this.flatNodes[value] !== undefined) {
          this.flatNodes[value][listKey] = true;
        }
      });
    });
  }

  serializeList(key: string): fixMe {
    const list: fixMe = [];

    Object.keys(this.flatNodes).forEach((value) => {
      if (this.flatNodes[value][key]) {
        list.push(value);
      }
    });

    return list;
  }

  expandAllNodes(expand: boolean): NodeModel {
    Object.keys(this.flatNodes).forEach((value) => {
      if (this.flatNodes[value].isParent) {
        this.flatNodes[value].expanded = expand;
      }
    });

    return this;
  }

  toggleChecked(
    node: fixMe,
    isChecked: boolean,
    checkModel: CheckModelType,
    noCascade: boolean,
    percolateUpward: boolean = true
  ): NodeModel {
    const flatNode = this.flatNodes[node?.value];
    const modelHasParents =
      [CheckModel.PARENT, CheckModel.ALL].indexOf(checkModel) > -1;
    const modelHasLeaves =
      [CheckModel.LEAF, CheckModel.ALL].indexOf(checkModel) > -1;

    if (flatNode?.isLeaf || noCascade) {
      if (node?.disabled) {
        return this;
      }

      this.toggleNode(node?.value, 'checked', isChecked);
    } else {
      // Toggle parent check status if the model tracks this OR if it is an empty parent
      if (modelHasParents || flatNode?.children.length === 0) {
        this.toggleNode(node?.value, 'checked', isChecked);
      }

      if (modelHasLeaves) {
        // Percolate check status down to all children
        flatNode?.children.forEach((child: fixMe) => {
          this.toggleChecked(child, isChecked, checkModel, noCascade, false);
        });
      }
    }

    // Percolate check status up to parent
    // The check model must include parent nodes and we must not have already covered the
    // parent (relevant only when percolating through children)
    if (percolateUpward && !noCascade && flatNode?.isChild && modelHasParents) {
      this.toggleParentStatus(flatNode?.parent, checkModel);
    }

    return this;
  }

  toggleParentStatus(node: fixMe, checkModel: CheckModelType): void {
    const flatNode = this.flatNodes[node?.value];

    if (flatNode?.isChild) {
      if (checkModel === CheckModel.ALL) {
        this.toggleNode(
          node?.value,
          'checked',
          this.isEveryChildChecked(flatNode)
        );
      }

      this.toggleParentStatus(flatNode?.parent, checkModel);
    } else {
      this.toggleNode(
        node?.value,
        'checked',
        this.isEveryChildChecked(flatNode)
      );
    }
  }

  isEveryChildChecked(node: fixMe): boolean {
    return node?.children.every(
      (child: fixMe) => this.getNode(child?.value).checked
    );
  }

  toggleNode(nodeValue: fixMe, key: string, toggleValue: boolean): NodeModel {
    this.flatNodes[nodeValue][key] = toggleValue;

    return this;
  }
}
