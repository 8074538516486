import { Shell } from '@components/AutoComplete';
import { Dropdown } from '@components/Dropdown';
import { useFlagMe38173ViewGroupOptionInSidebar } from '@generated/flags/ME-38173-view-group-option-in-sidebar';
import { FC } from 'react';
import { SearchType } from '../types';

interface Props {
  setSearchType: (searchType: SearchType) => void;
  searchType: SearchType;
}

export const SearchTypeDropDown: FC<Props> = ({
  setSearchType,
  searchType,
}) => {
  const options: SearchType[] = [
    SearchType.rep,
    SearchType.carrier,
    SearchType.customer,
    SearchType.facility,
    SearchType.load,
  ];

  const viewGroupOptionFlag = useFlagMe38173ViewGroupOptionInSidebar();
  if (viewGroupOptionFlag) {
    options.push(SearchType.group);
  }

  return (
    <Dropdown
      fsName={searchType}
      data-testid="search-type-dropdown"
      css={{
        position: 'relative',
      }}
      items={options.map(
        (option): Shell<SearchType> => ({
          value: option,
          label: option,
        })
      )}
      selectedItem={{
        value: searchType,
        label: searchType,
      }}
      onChange={(item) => {
        setSearchType(item?.value ?? SearchType.rep);
      }}
    />
  );
};
