import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type DriverMessagingEventV3PayloadFragment = (
  { readonly __typename?: 'DriverMessagingEventV3' }
  & Pick<TP.DriverMessagingEventV3, 'id' | 'drivers' | 'assignmentId' | 'followUpTimeId' | 'errors'>
  & { messageNullable: TP.DriverMessagingEventV3['message'] }
  & { readonly conversation: (
    { readonly __typename?: 'Conversation' }
    & Pick<TP.Conversation, 'classification' | 'value'>
  ) }
);

export const DriverMessagingEventV3PayloadFragmentDoc = gql`
    fragment DriverMessagingEventV3Payload on DriverMessagingEventV3 {
  id
  conversation {
    classification
    value
  }
  drivers
  assignmentId
  followUpTimeId
  messageNullable: message
  errors
}
    `;