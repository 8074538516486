import { TOOLTIP_ERROR_LIST_CLASS } from '@components/Button/SaveButton/util';
// eslint-disable-next-line no-restricted-imports
import { EL_DIALOG_INNER_CONTENT } from '@components/Dialog/constants';
import {
  FULL_SCREEN_DIALOG_ID,
  MASTERFIND_DIALOG_ID,
} from '@components/Dialog/util';
import { newColors } from '@components/Theme/colors';
import { BODY_FONT_SIZE } from '@components/Theme/constants';
import { visuallyHiddenStyle } from '@components/VisuallyHidden';
import { Global } from '@emotion/react';
import { ENV_VARS } from '@env';

import { FULLSTORY_DEBUG_ENABLED } from '@hooks/useFullstoryDebug';
import { useInputStyle } from '@hooks/useInputStyle';
import { useOutlineRing } from '@hooks/useOutlineRing';
import { useTheme } from '@hooks/useTheme';
import '@reach/dialog/styles.css';
import '@reach/menu-button/styles.css';
import { CALENDAR, MODAL_BACKGROUND, REACH_MENU } from '@utils/zIndex';
import { round } from 'lodash-es';
import { FC } from 'react';
import { calendar6BgImg, clock5BgImg } from './iconBgImg';

const DEFAULT_LINE_HEIGHT = `${round(BODY_FONT_SIZE * 1.3)}px`;

export const AppStyles: FC = () => {
  const { colors, gray, fonts, card, tooltip, contextMenu, formElement } =
    useTheme();
  const inputStyle = useInputStyle();
  const getOutLineRing = useOutlineRing();

  return (
    <Global
      styles={{
        '*': {
          boxSizing: 'border-box',
          '&:focus:not(:focus-visible)': {
            outline: 'none',
          },
          '&:focus-visible': {
            outline: getOutLineRing(),
          },
          '.focusable:focus-visible': {
            outline: getOutLineRing(),
          },
          '.validation-error:focus-visible': {
            outline: getOutLineRing(colors.error),
          },
          '.validation-warningDark:focus-visible': {
            outline: getOutLineRing(colors.warningDark),
          },
          '.validation-success:focus-visible': {
            outline: getOutLineRing(colors.accent),
          },
          fontFamily: FULLSTORY_DEBUG_ENABLED
            ? 'Redacted !important'
            : undefined,
        },
        body: {
          fontFamily: fonts.main,
          webkitFontSmoothing: 'antialiased',
          mozOsxFontSmoothing: 'grayscale',
          fontSize: BODY_FONT_SIZE,
          lineHeight: DEFAULT_LINE_HEIGHT,
          color: colors.text,
        },
        '#overlay': {
          display: 'var(--overlay-display)',
          background: 'var(--overlay-bg)',
          position: 'fixed',
          width: '100%',
          height: '100vh',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 5,
          textAlign: 'center',
          fontWeight: 'bold',
        },
        '#connected-tabs': {
          display: 'var(--connected-tabs-display)',
          background: 'var(--connected-tabs-bg)',
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
          height: 20,
        },
        a: {
          color: colors.text,
          '&:visited': {
            color: colors.text,
          },
        },
        '[class*="spectrum-Dialog-content"]': {
          overflowY: 'unset !important' as anyOk,
          lineHeight: `${DEFAULT_LINE_HEIGHT} !important` as anyOk,
          fontSize: `${BODY_FONT_SIZE.toString()}px !important` as anyOk,
          fontWeight: 'normal !important' as anyOk,
          padding: '0px !important',
          maxWidth: '100%',
        },
        // Yeah this is pretty brittle, but it's the best css only way to target this for table expand mode as of writing.
        [`[class*=spectrum][lang]:has([data-dialog-id="${FULL_SCREEN_DIALOG_ID}"]) [class*=spectrum-Underlay]`]:
          {
            background: `none !important`,
          },
        [`[class*=spectrum][lang]:has([data-dialog-id="${MASTERFIND_DIALOG_ID}"]) [class*=spectrum-Underlay]`]:
          {
            background: `none !important`,
            pointerEvents: 'none !important' as anyOk,
          },
        [`[data-testid="modal"]:has(#${MASTERFIND_DIALOG_ID}-dialog)`]: {
          transition: 'none !important',
          border: 0,
        },
        // this only targets dialog v2
        '[data-testid=dialog][role=dialog]': {
          height: '100%',
          /** Override default spectrum overflow. Allows things like dropdowns to be scrolled to https://masterysys.atlassian.net/browse/ME-264672 */
          overflow: 'visible',
          // setting padding will have no effect from consumers
          padding: '0px !important',
          // setting margin will have no effect from consumers
          margin: '0 !important',
        },
        '[class*=spectrum-Textfield]': {
          fontFamily: `${fonts.main} !important`,
        },
        '[class*=spectrum-Menu-item]': {
          cursor: 'pointer',
        },
        '[class*="spectrum-Dialog--small"][data-testid="dialog"]': {
          minWidth: '360px',
          width: 'unset',
        },
        '[class*="spectrum-Dialog-grid"]': {
          gridTemplateRows: '1fr !important',
          gridTemplateColumns: '1fr !important',
          gridTemplateAreas: 'none !important',
          placeContent: 'center !important',
          display: 'flex !important',
        },
        '[data-testid="modal"]': {
          border: `2px solid ${gray[99]}`,
          borderRadius: '4px',
          display: 'flex',
        },
        [`[data-testid="modal"]:has(#${FULL_SCREEN_DIALOG_ID}-dialog)`]: {
          maxWidth: '100vw !important',
          maxHeight: '100vh !important',
          transition: 'none !important',
          border: '0px !important',
          '[data-testid="dialog-content"]': {
            padding: '0px !important',
          },
          [`#${EL_DIALOG_INNER_CONTENT}`]: {
            padding: '46px 46px 20px !important',
            background: gray[95],
          },
          '[class*="spectrum-Dialog-content"]': {
            maxWidth: '100vw !important',
            maxHeight: '100vh !important',
            width: '100vw !important',
            height: '100vh  !important',
            display: 'grid',
            borderRadius: 0,
          },
          '[data-dialog-close]': {
            top: 0,
            right: 0,
            width: 48,
            height: 46,
          },
        },
        // TODO: remove the below code when dialog v1 is fully removed useFlagMe214355SpectrumUpgradeDialog
        [`[data-testid="dialog"]#${FULL_SCREEN_DIALOG_ID}-dialog`]: {
          paddingTop: '0 !important',
          // eslint-disable-next-line mastery/dialog-css
          '[role=dialog]': {
            maxWidth: '100vw',
            maxHeight: '100vh',
            width: '100vw',
            height: '100vh',
            display: 'grid',
            borderRadius: 0,
            transition: 'none !important',
            margin: '0 !important',
            background: gray[95],
          },
        },
        address: {
          display: 'inline-block',
          fontStyle: 'inherit',
        },
        code: { fontFamily: fonts.code },
        pre: {
          fontFamily: 'inherit',
          whiteSpace: 'pre',
          display: 'inline',
          margin: 0,
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        button: {
          background: 'transparent',
          border: 'none',
          cursor: 'pointer',
          font: 'unset',
          fontFamily: fonts.main,
          color: 'inherit',
          padding: 0,
          fontWeight: 'normal',
          margin: 0,
          lineHeight: DEFAULT_LINE_HEIGHT,
        },
        p: { margin: 0 },
        h1: {
          margin: 0,
          paddingRight: '4px',
          fontSize: '24px',
        },
        input: inputStyle,
        '.input': inputStyle,
        'input[type="checkbox"]': {
          width: 'auto',
        },
        'input.timeInput': {
          background: clock5BgImg,
        },
        textarea: {
          ...inputStyle,
          display: 'block',
          minHeight: '80px',
        },
        select: {
          ...inputStyle,
          appearance: 'none',
          cursor: 'pointer',
          padding: '8px 28px 8px 8px',
        },
        '[data-readonly="true"]': {
          ...inputStyle,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          height: 'calc(1em + 20px)',
          borderColor: gray[90],
          backgroundColor: formElement.readOnly.background,
        },
        '[data-readonly="true"][data-input="TextArea"]': {
          ...inputStyle,
          display: 'block',
          minHeight: '80px',
          height: 'auto',
          overflow: 'auto',
          borderColor: gray[90],
          backgroundColor: formElement.readOnly.background,
        },
        label: {
          fontWeight: 500,
          whiteSpace: 'nowrap',
          display: 'inline-block',
          padding: '0 0 6px 0',
          cursor: 'pointer',
        },
        fieldset: {
          border: 0,
          padding: 0,
          margin: 0,
          minWidth: 0,
        },
        legend: {
          border: 0,
          padding: 0,
          margin: 0,
          minWidth: 0,
        },
        '.table-striped': {
          width: '100%',
          borderCollapse: 'collapse',
          borderColor: gray[90],

          th: {
            padding: '8px 16px',
            textAlign: 'left',
          },

          tbody: {
            '& tr:nth-of-type(odd) td': {
              background: gray[95],
              borderBottom: `1px solid ${gray[80]}`,
            },
          },
          td: {
            padding: '8px 16px',
            wordWrap: 'break-word',

            '&:last-of-type': { textAlign: 'right' },
          },
        },
        '.react-datepicker-wrapper': {
          display: 'block',
          width: 'unset',
          '[aria-label="Close"]': {
            display: 'none',
          },
        },
        '.react-datepicker__input-container': {
          display: 'block',
          width: 'unset',
          input: {
            background: calendar6BgImg,
          },
        },
        '.react-datepicker-popper': {
          zIndex: `${CALENDAR} !important` as anyOk,
        },
        '#spectrum-provider': {
          width: '100%',
          display: 'table',
        },
        '[class*="_spectrum"][lang]': {
          background: 'none !important',
          isolation: 'auto !important' as anyOk,
        },
        '[class*="spectrum_"]': {
          isolation: 'auto !important' as anyOk,
        },
        '[class*="spectrum-Underlay--transparent"]': {
          pointerEvents: 'none !important' as anyOk,
        },
        '[class*=spectrum-FocusRing-ring]:after': {
          display: 'none !important',
        },
        '[class*=_focus-ring]:before': {
          display: 'none !important',
        },
        '#walkme-player': visuallyHiddenStyle,
        '.Toastify__toast-body': {
          whiteSpace: 'pre-wrap',
        },
        '.Toastify__toast-container': {
          zIndex: '200000 !important',
        },
        '.Toastify__toast--warning': {
          color: newColors.system.warning.text,
          background: newColors.system.warning.background,
          border: `solid ${newColors.system.warning.accent}`,
        },
        '.Toastify__toast--info': {
          background: newColors.system.info.background,
          border: `solid ${newColors.system.info.accent}`,
          color: newColors.system.info.text,
        },
        '.Toastify__toast--error': {
          background: newColors.system.error.background,
          border: `solid ${newColors.system.error.accent}`,
          color: newColors.system.error.text,
        },
        '.Toastify__toast--success': {
          background: newColors.system.success.background,
          border: `solid ${newColors.system.success.accent}`,
          color: newColors.system.success.text,
        },
        '.Toastify__toast': {
          borderWidth: '1px 1px 1px 8px',
          borderRadius: '4px',
        },
        '[data-reach-dialog-content]': {
          overflow: 'auto',
        },
        '[data-reach-tab-list]': {
          background: 'none',
          fontWeight: '400 !important',
        },
        'div[data-reach-tooltip]': {
          ...tooltip,
        },
        [`.${TOOLTIP_ERROR_LIST_CLASS}`]: {
          padding: '0 0 0 10px',
        },
        ['.fs-unmask, .fs-unmask *']: {
          fontFamily: `${fonts.main} !important`,
        },
        ['.fs-mask, .fs-mask *']: {
          fontFamily: FULLSTORY_DEBUG_ENABLED
            ? 'Redacted !important'
            : undefined,
        },
        ['.fs-mask *']: ENV_VARS.VITE_DEBUG_FS_MASK && {
          textShadow: 'none',
        },
        '[data-reach-menu]': {
          zIndex: REACH_MENU,
          fontFamily: fonts.main,
        },
        '[class*=spectrum-Underlay]': {
          zIndex: `${MODAL_BACKGROUND} !important`,
        },
        ['[class*=spectrum-Modal-wrapper]']: {
          zIndex: `${MODAL_BACKGROUND} !important`,
        },
        '[data-testid="modal"]:has([data-dialog-show="false"])': {
          // prevents a flash of primary modal with no content before a secondary is shown
          border: 0,
        },
        '[class*=spectrum-ActionButton] svg': {
          marginRight: '6px !important',
        },
        '[id*=dateonly-datepicker]': {
          fontSize: '12px !important',
          width: '100% !important',
          fontFamily: `${fonts.main}  !important`,
        },
        '[id*=datepicker-years]': {
          fontSize: '12px !important',
          width: '115px !important',
          minWidth: '110px !important',
          fontFamily: `${fonts.main}  !important`,
        },
        '[data-reach-menu-item][aria-disabled]': {
          opacity: '1 !important',
        },
        '[data-reach-tab]:disabled': {
          opacity: '1 !important',
        },
        '[data-reach-tab][data-selected]': {
          fontWeight: '500 !important',
        },
        '[data-reach-menu-list]': {
          border: 0,
          padding: 0,
          fontWeight: 500,
          overflow: 'hidden',
          fontSize: 'inherit',
          borderRadius: 2,
          boxShadow: '0 0 7px 5px rgba(0, 0, 0, 0.03)',
          ...contextMenu,
          '[data-reach-menu-item]': {
            fontWeight: 600,
            whiteSpace: 'nowrap',
            padding: '10px 15px',
            '&[data-selected]': {
              background: card.background,
              color: colors.primary,
              '&:hover': {
                color: colors.primary,
              },
              '&[data-contextmenuitem]': {
                color: gray[30],
                '&:hover': {
                  color: colors.primary,
                },
              },
            },
            '&[disabled]': {
              color: gray[90],
            },
          },
        },
        // '#content > *': {
        //   '-webkit-app-region': 'no-drag',
        // },
      }}
    />
  );
};
