import { CSSObject } from '@emotion/react';
import { useFlagMe139059FixMinimumWidthPhoneinputComponent } from '@generated/flags/ME-139059-fix-minimum-width-phoneinput-component';
import { useFullstoryElement } from '@hooks/useFullstory';
import {
  FullStoryElements,
  FullStoryElementType,
  FullStoryTypes,
} from '@utils/fullstory';
import { FC, forwardRef, useCallback, useEffect, useRef } from 'react';
import { PhoneInputProps } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { PhoneValue, usePhoneLib } from '../PhoneValue';
import { CountrySelect } from './CountrySelect';

export interface Props extends Omit<PhoneInputProps, 'value' | 'country'> {
  value?: Maybe<string>;
  /** Defaults to US */
  hideCountryPicker?: boolean;
  /** Internal prop for PhoneField, do not use */
  internalOnChangeKey?: number;
  fsName?: string;
  fsParent?: string;
  fsType?: FullStoryTypes;
  fsElement?: FullStoryElementType;
}

const CountryPickerNoop = (): null => null;

const CustomInputEl = forwardRef<HTMLInputElement, anyOk>((rawProps, ref) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    useNationalFormatForDefaultCountryValue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    extraBottomPadding,
    ...props
  } = rawProps;
  return <input {...props} ref={ref} />;
});

export const DEFAULT_COUNTRY = 'US';

export const coerceToDefaultCountry = (kwargs: {
  phoneLib: Maybe<typeof import('react-phone-number-input')>;
  defaultCountry: string;
  value: Maybe<string>;
}): string => {
  const { phoneLib, defaultCountry, value } = kwargs;
  let coercedValue = value ?? '';
  if (phoneLib) {
    const countryCode = phoneLib.getCountryCallingCode(defaultCountry);
    if (coercedValue && !coercedValue.startsWith('+') && countryCode) {
      coercedValue = `+${countryCode} ${coercedValue}`;
    }
  }
  return coercedValue;
};

export const PhoneInput: FC<Props> = (rawProps) => {
  const enablePhoneInputMinimumWidth =
    useFlagMe139059FixMinimumWidthPhoneinputComponent();
  const phoneLib = usePhoneLib();
  const {
    hideCountryPicker,
    defaultCountry = DEFAULT_COUNTRY,
    internalOnChangeKey: onChangeKey,
    fsName,
    fsParent,
    fsType = 'phone',
    fsElement = FullStoryElements.FIELD_INPUT,
    ...props
  } = rawProps;
  const { getFsComponentProps } = useFullstoryElement();
  const coercedValue = props.value || '';

  const hasNotifiedRef = useRef(false);

  const triggerCoerceChange = useCallback((): void => {
    if (phoneLib) {
      const modifiedCoercedValue = coerceToDefaultCountry({
        phoneLib,
        defaultCountry,
        value: coercedValue,
      });
      hasNotifiedRef.current = true;
      props.onChange(modifiedCoercedValue);
    }
  }, [coercedValue, defaultCountry, phoneLib, props]);

  useEffect(() => {
    if (phoneLib && !hasNotifiedRef.current) {
      triggerCoerceChange();
    }
  }, [coercedValue, defaultCountry, phoneLib, props, triggerCoerceChange]);

  useEffect(() => {
    triggerCoerceChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChangeKey]);

  if (!phoneLib) {
    return <input disabled />;
  }

  const Component = phoneLib.default;

  if (props.readOnly) {
    return (
      <div data-readonly>
        <PhoneValue value={props.value} />
      </div>
    );
  }

  const hasNonUSCoercedCountry =
    (props.value || '').startsWith('+') &&
    !(props.value || '').startsWith('+1');

  const extraProps: anyOk = {
    initialValueFormat: hideCountryPicker ? undefined : 'national',
  };

  if (hasNonUSCoercedCountry) {
    extraProps.initialValueFormat = 'international';
  }

  const phoneInputWidthStyle: CSSObject = {
    'input[type="tel"]': {
      minWidth: '115px',
    },
  };
  return (
    <div
      css={{
        '.PhoneInput': {
          display: 'grid',
          gridTemplateColumns: hideCountryPicker ? '1fr' : '36px 1fr',
          gap: 2,
        },
      }}
      {...getFsComponentProps({
        name: fsName ?? 'phone',
        parent: fsParent,
        element: fsElement,
        type: fsType,
      })}
    >
      <Component
        defaultCountry="US"
        {...extraProps}
        countrySelectComponent={
          hideCountryPicker ? CountryPickerNoop : CountrySelect
        }
        withCountryCallingCode={hideCountryPicker}
        international={hideCountryPicker}
        {...props}
        inputComponent={CustomInputEl}
        useNationalFormatForDefaultCountryValue
        value={coercedValue}
        css={enablePhoneInputMinimumWidth && { ...phoneInputWidthStyle }}
      />
    </div>
  );
};
