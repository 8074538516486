import { ButtonOption } from '@components/Button/oldButton';
import { IconProp } from '@components/Icon';

export type SystemAlertType = 'success' | 'warning' | 'error' | 'info';

export interface AlertTypeProps extends React.HTMLAttributes<HTMLDivElement> {
  type: 'success' | 'warning' | 'error' | 'info';
}

export const TITLE_BY_TYPE: Record<SystemAlertType, string> = {
  success: 'Success',
  warning: 'Warning',
  error: 'Alert',
  info: 'Information',
};

export const ICONS_BY_TYPE: Record<SystemAlertType, IconProp> = {
  success: 'checkCircleSolid',
  warning: 'exclamationTriangleSolid',
  error: 'exclamationCircleSolid',
  info: 'infoCircleSolid',
};

export const BUTTONS_BY_TYPE: Record<SystemAlertType, ButtonOption> = {
  success: 'successSystemDialog',
  warning: 'warningSystemDialog',
  error: 'errorSystemDialog',
  info: 'infoSystemDialog',
};

export const BUTTONS_BY_TYPE_INVERSE: Record<SystemAlertType, ButtonOption> = {
  success: 'successSystemDialogInverse',
  warning: 'warningSystemDialogInverse',
  error: 'errorSystemDialogInverse',
  info: 'infoSystemDialogInverse',
};
