import { Icon } from '@components/Icon';
import { useTheme } from '@hooks/useTheme';
import { Menu, MenuButton, MenuList, MenuItem as MI } from '@reach/menu-button';
import { FS_UNMASK, fsEvent } from '@utils/fullstory';
import cx from 'classnames';
import { FC, ReactNode } from 'react';

interface Props {
  text: ReactNode;
  id?: string;
}

export const MenuItem: FC<{
  onSelect: () => void;
  children: ReactNode;
  className?: string;
  fsId: string;
}> = ({ fsId, ...props }) => {
  return (
    <MI
      {...props}
      data-testid={fsId}
      className={cx(FS_UNMASK, props.className)}
      onSelect={() => {
        fsEvent('menu_item_click', {
          optionId: fsId,
        });
        props.onSelect();
      }}
    >
      {props.children}
    </MI>
  );
};

/** Direct children of ToggleMenu MUST be MenuItem or MenuLink elements */
export const ToggleMenu: FC<Props> = (props) => {
  const { card, gray } = useTheme();
  const toggleGray = gray[80];
  return (
    <div
      id={props.id}
      className={FS_UNMASK}
      css={{
        position: 'relative',
        '[data-reach-menu-button]': {
          fontSize: 14,
          color: toggleGray,
          padding: 20,
          '&:hover': {
            color: card.background,
          },
          '&[aria-expanded="true"]': {
            color: card.background,
          },
        },
      }}
    >
      <Menu>
        {({
          isOpen,
        }: fixMe): // TS typings for @reach/menu-button are currently incorrect and do not reflect the render prop options
        ReactNode => (
          <>
            <MenuButton>
              {props.text}
              <Icon
                i={isOpen ? 'angleUp' : 'angleDown'}
                size="sm"
                color="inherit"
                css={{ marginLeft: 6 }}
                aria-hidden
              />
            </MenuButton>
            {isOpen && (
              <MenuList
                css={{
                  marginTop: -8,
                }}
              >
                {props.children}
              </MenuList>
            )}
          </>
        )}
      </Menu>
    </div>
  );
};
