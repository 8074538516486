import {
  Content,
  DialogContainer,
  Dialog as SpectrumDialog,
} from '@adobe/react-spectrum';
import { CloseButton, CloseButtonProps } from '@components/Button/CloseButton';
import { ModalHeader } from '@components/styled';
import { CSSObject, css } from '@emotion/react';
import { useFlagMe214355SpectrumUpgradeDialog } from '@generated/flags/ME-214355-spectrum-upgrade-dialog';
import { useTheme } from '@hooks/useTheme';
import {
  DialogContent,
  DialogContentProps,
  DialogOverlay,
} from '@reach/dialog';
import { PropsWithAs } from '@reach/utils';
import { HEADER_HEIGHT } from '@utils/constants';
import { FullStoryType } from '@utils/fullstory';
import { Workflow } from '@utils/fullstory/context';
import { MODAL_BACKGROUND } from '@utils/zIndex';
import { useAtom } from 'jotai';
import { uniq, uniqueId, without } from 'lodash-es';
import { CSSProperties, FC, useEffect, useRef } from 'react';
import { useUnmount } from 'react-use';
import { SaveButton } from '../Button/SaveButton';
import { Padding } from '../Padding';
import { EL_DIALOG_INNER_CONTENT } from './constants';
import { IsInDialogContext, activeDialogIdsAtom } from './context';
import { FULL_SCREEN_DIALOG_ID } from './util';

const activeStyle = css({
  display: 'flex',
});

const modalBackground: CSSObject = {
  position: 'fixed',
  zIndex: MODAL_BACKGROUND,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  alignItems: 'center',
  display: 'none',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'hidden',
  paddingTop: HEADER_HEIGHT,
};

const DIALOG_STANDARD_WIDTH = 640;

const modalContent: CSSObject = {
  position: 'relative',
  minHeight: 'auto',
  padding: '48px',
  borderRadius: '3px',
  width: DIALOG_STANDARD_WIDTH,
  maxHeight: 'calc(100vh - 56px)',
  overflow: 'visible',
};

const modalContentV2: CSSObject = {
  minHeight: 'auto',
  maxHeight: '100%',
  maxWidth: '100%',
  padding: '20px 2px',
  borderRadius: '3px',
};

export interface Props {
  active: boolean;
  /** @deprecated Use `onClose` prop */
  closeModal?: () => void;
  onClose?: () => void;
  confirmationText?: string;
  onConfirmDisabled?: boolean;
  confirmButtonEnabled?: boolean;
  id: string;
  onConfirm?: () => void;
  style?: CSSObject;
  className?: string;
  fsProps?: FullStoryType;
  contentStyle?: Pick<
    CSSProperties,
    | 'height'
    | 'minHeight'
    | 'maxHeight'
    | 'width'
    | 'minWidth'
    | 'maxWidth'
    | 'overflow'
    | 'overflowX'
    | 'overflowY'
    | 'textAlign'
  > & {
    /** @deprecated Dialog padding should not be altered */
    padding?: string | number;
    /** @deprecated Dialog padding should not be altered */
    paddingTop?: string | number;
    /** @deprecated Dialog padding should not be altered */
    paddingRight?: string | number;
    /** @deprecated Dialog padding should not be altered */
    paddingBottom?: string | number;
    /** @deprecated Dialog padding should not be altered */
    paddingLeft?: string | number;
    /** @deprecated Dialog margin should not be altered */
    margin?: string | number;
  };
  dialogContentProps?: PropsWithAs<'div', DialogContentProps>;
  /** @deprecated This should not be used. Contact Atlas. */
  forcev2?: boolean;
  /** @deprecated This should not be used. Contact Atlas. */
  forcev1?: boolean;
  /** Internal prop, do not use unless you know what you are doing and have tests written. */
  dangerousBypassFocusLock?: boolean;
}

// ts-unused-exports:disable-next-line
export const DialogCloseButton: FC<CloseButtonProps> = (
  props: CloseButtonProps
) => {
  return (
    <CloseButton data-testid="dialog-close" data-dialog-close {...props} />
  );
};

export const Dialog: FC<Props> = ({
  active: isActive,
  closeModal: closeModalProp,
  onClose,
  confirmationText = 'Save',
  onConfirmDisabled = false,
  confirmButtonEnabled = true,
  forcev2 = false,
  forcev1 = false,
  id,
  onConfirm,
  style,
  children,
  className,
  contentStyle,
  dialogContentProps,
  dangerousBypassFocusLock = false,
  fsProps,
}) => {
  const { dialog } = useTheme();
  const contextBtnId = useRef(uniqueId());
  const spectrumUpgradeDialog = useFlagMe214355SpectrumUpgradeDialog();

  const closeModal = (): void => {
    onClose?.();
    closeModalProp?.();
  };

  const shouldShowConfirmButton = onConfirm && confirmButtonEnabled;

  const [activeDialogIds, setActiveDialogIds] = useAtom(activeDialogIdsAtom);

  useEffect(() => {
    if (isActive) {
      setActiveDialogIds((prev) => uniq(prev.concat(id)));
    } else {
      setActiveDialogIds((prev) => without(prev, id));
    }
    // ⚠️ Do not include activeDialogIds in the dependencies array or it will cause an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isActive]);

  // Ensure that the dialog is removed from the activeDialogIds array on unmount
  useUnmount(() => {
    setActiveDialogIds((prev) => without(prev, id));
  });

  const isLast = activeDialogIds[activeDialogIds.length - 1] === id;
  let shouldShow = isLast;
  if (id === FULL_SCREEN_DIALOG_ID) {
    shouldShow = true;
  } else if (isActive && !activeDialogIds.length) {
    // this condition avoids a race condition in which isActive prop comes through as true before the effect above has fired off. UserFeedback component is an example of this, and has a test to cover this case. Without this, autoFocus does not work correctly due to css display: none
    shouldShow = true;
  }

  if ((spectrumUpgradeDialog || forcev2) && !forcev1) {
    return (
      <Workflow id={id}>
        <IsInDialogContext.Provider value>
          <DialogContainer
            onDismiss={(): void => {
              closeModal();
            }}
          >
            {isActive && (
              <div
                className={className}
                css={{
                  maxWidth: '100%',
                  ...style,
                  display: shouldShow ? 'block' : 'none',
                }}
                data-dialog-show={shouldShow}
              >
                <SpectrumDialog
                  role="dialog"
                  data-testid={'dialog'}
                  id={`${id}-dialog`}
                  data-isopen={isActive}
                  aria-label="modal"
                  data-modalactive={isActive}
                  data-active={isActive}
                  size="S"
                  data-dialog-id={id}
                >
                  <div
                    data-testid="dialog-content"
                    css={{
                      ...modalContentV2,
                      ...dialog.content,
                      backgroundColor: 'inherit',
                    }}
                  >
                    <Content
                      UNSAFE_style={{
                        height: '100%',
                        width: DIALOG_STANDARD_WIDTH,
                        margin: 0,
                        ...contentStyle,
                      }}
                    >
                      <div
                        id={EL_DIALOG_INNER_CONTENT}
                        css={{
                          height: '100%',
                          padding: '12px 36px',
                          position: 'relative',
                        }}
                      >
                        <DialogCloseButton
                          id={fsProps?.fsType}
                          onClick={() => closeModal()}
                          css={{
                            top: 0,
                            right: 6,
                          }}
                        />
                        <div
                          css={{
                            height: shouldShowConfirmButton
                              ? 'calc(100% - 36px)'
                              : '100%',
                          }}
                          {...dialogContentProps}
                        >
                          {children}
                          {shouldShowConfirmButton && (
                            <Padding t2 css={{ textAlign: 'center' }}>
                              <SaveButton
                                data-testid="dialog-confirm"
                                css={{ margin: '0 auto', display: 'block' }}
                                disabled={(): boolean => onConfirmDisabled}
                                onClick={onConfirm}
                                id={`dialog-confirm-${
                                  contextBtnId.current || 0
                                }`}
                                name={`dialog-confirm-${
                                  contextBtnId.current || 0
                                }`}
                              >
                                {confirmationText}
                              </SaveButton>
                            </Padding>
                          )}
                        </div>
                      </div>
                    </Content>
                  </div>
                </SpectrumDialog>
              </div>
            )}
          </DialogContainer>
        </IsInDialogContext.Provider>
      </Workflow>
    );
  }
  return (
    <Workflow id={id}>
      <IsInDialogContext.Provider value>
        <DialogOverlay
          css={[
            modalBackground,
            isActive && activeStyle,
            dialog.overlay,
            style,
          ]}
          className={className}
          data-testid="dialog"
          id={`${id}-dialog`}
          isOpen={isActive}
          aria-label="modal"
          data-modalactive={isActive}
          onDismiss={closeModal}
          dangerouslyBypassFocusLock={dangerousBypassFocusLock}
        >
          <DialogContent
            aria-label="modal"
            css={{ ...modalContent, ...dialog.content, ...contentStyle }}
            {...dialogContentProps}
          >
            <DialogCloseButton onClick={closeModal} />
            {children}
            {shouldShowConfirmButton && (
              <Padding t2 css={{ textAlign: 'center' }}>
                <SaveButton
                  data-testid="dialog-confirm"
                  css={{ margin: '0 auto', display: 'block' }}
                  disabled={(): boolean => onConfirmDisabled}
                  onClick={onConfirm}
                  id={`dialog-confirm-${contextBtnId.current || 0}`}
                  name={`dialog-confirm-${contextBtnId.current || 0}`}
                >
                  {confirmationText}
                </SaveButton>
              </Padding>
            )}
          </DialogContent>
        </DialogOverlay>
      </IsInDialogContext.Provider>
    </Workflow>
  );
};

export const DialogTitle = ModalHeader;
