import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type BookingFailureEventPayloadFragment = (
  { readonly __typename?: 'BookingFailureEvent' }
  & Pick<TP.BookingFailureEvent, 'id' | 'type'>
  & { readonly bookingErrors?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'BookingError' }
    & Pick<TP.BookingError, 'code' | 'message'>
  )>> }
);

export const BookingFailureEventPayloadFragmentDoc = gql`
    fragment BookingFailureEventPayload on BookingFailureEvent {
  id
  type
  bookingErrors: errors {
    code
    message
  }
}
    `;