import * as TP from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateOrUpdateTabPreferenceMutationVariables = TP.Exact<{
  input: TP.TabPreferenceInput;
}>;


export type CreateOrUpdateTabPreferenceMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createOrUpdateTabPreference: (
    { readonly __typename?: 'TabPreferenceResponseType' }
    & Pick<TP.TabPreferenceResponseType, 'createdAt' | 'createdBy' | 'updatedAt' | 'tabGroupId'>
    & { readonly tabs: ReadonlyArray<(
      { readonly __typename?: 'Tab' }
      & Pick<TP.Tab, 'id' | 'default' | 'isVisible' | 'order'>
    )> }
  ) }
);


export const CreateOrUpdateTabPreferenceDocument = gql`
    mutation createOrUpdateTabPreference($input: TabPreferenceInput!) {
  createOrUpdateTabPreference(input: $input) {
    createdAt
    createdBy
    updatedAt
    tabGroupId
    tabs {
      id
      default
      isVisible
      order
    }
  }
}
    `;
export type CreateOrUpdateTabPreferenceMutationFn = Apollo.MutationFunction<CreateOrUpdateTabPreferenceMutation, CreateOrUpdateTabPreferenceMutationVariables>;

/**
 * __useCreateOrUpdateTabPreferenceMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateTabPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateTabPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateTabPreferenceMutation, { data, loading, error }] = useCreateOrUpdateTabPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateTabPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateTabPreferenceMutation, CreateOrUpdateTabPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateTabPreferenceMutation, CreateOrUpdateTabPreferenceMutationVariables>(CreateOrUpdateTabPreferenceDocument, options);
      }
export type CreateOrUpdateTabPreferenceMutationHookResult = ReturnType<typeof useCreateOrUpdateTabPreferenceMutation>;
export type CreateOrUpdateTabPreferenceMutationResult = Apollo.MutationResult<CreateOrUpdateTabPreferenceMutation>;
export type CreateOrUpdateTabPreferenceMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateTabPreferenceMutation, CreateOrUpdateTabPreferenceMutationVariables>;