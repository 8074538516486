import { Grid } from '@components/Grid';
import { Icon } from '@components/Icon';
import { PhoneValue } from '@components/Phone/PhoneValue';
import { EmployeeDetailsEmployeeTreeV2Fragment } from '@generated/fragments/employeeDetailsEmployeeTreeV2';
import { EmployeeInfoV2Fragment } from '@generated/fragments/employeeInfoV2';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Tooltip } from '../../../components/Tooltip';
import { TreeNode } from '../../../components/TreePicker';
import { EmployeeTreeNodeValue } from '../util';

export type EmployeeTreeInfoType = EmployeeInfoV2Fragment | undefined | null;

export const showDisabledIcon = (
  employee: EmployeeDetailsEmployeeTreeV2Fragment | undefined | null,
  selectedEmployeeIsActive: string,
  isTeamsMinionEnabled: boolean | undefined,
  isSlackMinionEnabled: boolean | undefined
): boolean => {
  if (!employee) {
    return true; // Disable icon if employee data is missing
  }

  // Show disabled icon if the employee is not active
  if (selectedEmployeeIsActive !== 'Active') {
    return true;
  }

  // Show disabled icon if neither Teams nor Slack is enabled
  if (!isTeamsMinionEnabled && !isSlackMinionEnabled) {
    return true;
  }

  // If either Teams or Slack is enabled and employee is active, do not show the disabled icon
  return false;
};

export const isSlackUserExists = (
  employee: EmployeeDetailsEmployeeTreeV2Fragment,
  grayColor: string,
  openModal: () => void,
  selectedEmployeeIsActive: string,
  setSelectedEmployee: Dispatch<
    SetStateAction<EmployeeDetailsEmployeeTreeV2Fragment | undefined>
  >,
  isTeamsMinionEnabled: boolean | undefined,
  isSlackMinionEnabled: boolean | undefined
): ReactNode => {
  return (
    <>
      {showDisabledIcon(
        employee,
        selectedEmployeeIsActive,
        isTeamsMinionEnabled,
        isSlackMinionEnabled
      ) ? (
        <Icon
          i="commentLinesRegular"
          data-testid="slack-employee-tree-chat-disabled"
          css={{ opacity: '0.3', pointerEvents: 'none', color: grayColor }}
          size="sm"
          color="inherit"
        />
      ) : (
        <>
          <a
            data-testid="slack-employee-tree-chat"
            onClick={(): void => {
              setSelectedEmployee(employee);
              openModal();
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon i="commentLinesRegular" size="sm" color="inherit" />
          </a>
        </>
      )}
    </>
  );
};

const getSlackServiceIcons = (
  employee: EmployeeDetailsEmployeeTreeV2Fragment,
  grayColor: string,
  openModal: () => void,
  selectedEmployeeIsActive: string,
  setSelectedEmployee: Dispatch<
    SetStateAction<EmployeeDetailsEmployeeTreeV2Fragment | undefined>
  >,
  isTeamsMinionEnabled: boolean | undefined,
  isSlackMinionEnabled: boolean | undefined
): ReactNode => {
  return (
    <div
      data-testid="slack-icon-block"
      css={{ float: 'right', 'margin-right': '10px' }}
    >
      {'messagingUserIdentities' in employee || isTeamsMinionEnabled ? (
        isSlackUserExists(
          employee,
          grayColor,
          openModal,
          selectedEmployeeIsActive,
          setSelectedEmployee,
          isTeamsMinionEnabled,
          isSlackMinionEnabled
        )
      ) : (
        <Icon
          i="commentLinesRegular"
          data-testid="slack-employee-tree-chat-disabled"
          css={{ opacity: '0.3', pointerEvents: 'none', color: grayColor }}
          size="sm"
          color="inherit"
        />
      )}
      &nbsp;
      <a
        data-testid="slack-employee-tree-email"
        href={'mailto:' + employee.email}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon i="envelope" size="sm" color="text" />
      </a>
    </div>
  );
};

const TreeNodeTooltipLabel = (
  employee: EmployeeDetailsEmployeeTreeV2Fragment,
  viewSlackEmployeeTreeFlag: boolean | undefined,
  grayColor: string,
  openModal: () => void,
  selectedEmployeeIsActive: string,
  setSelectedEmployee: Dispatch<
    SetStateAction<EmployeeDetailsEmployeeTreeV2Fragment | undefined>
  >,
  isTeamsMinionEnabled: boolean | undefined,
  isSlackMinionEnabled: boolean | undefined
): ReactNode => {
  return (
    <Tooltip
      label={
        <>
          <div>
            <b>{employee?.fullName}</b>
          </div>
          <div>{employee?.title}</div>

          <div>
            <PhoneValue value={employee?.phoneNumber} />
            <span style={{ float: 'right', margin: '0px' }}>
              {employee?.ext ? (
                <span style={{ margin: '0px 2px' }}>ext.</span>
              ) : (
                ''
              )}
              {employee?.ext}
            </span>
            <br />
          </div>
          <hr />
          <div>
            <b>Group: </b>
            {employee?.employeeGroup?.name}
          </div>
          <div>
            <b>Office: </b>
            {employee?.employeeOffice?.name}
          </div>
          <div>
            <b>Manager: </b>
            {employee?.manager?.fullName || 'N/A'}
          </div>
          <div>
            <b>Division: </b>
            {employee?.division?.name}
          </div>
        </>
      }
    >
      <div>
        <Grid sm="2fr 2fr">
          <div data-testid="employee-node-tooltip">{employee?.fullName}</div>
          <div data-testid="slack-employee-tree">
            {viewSlackEmployeeTreeFlag
              ? getSlackServiceIcons(
                  employee,
                  grayColor,
                  openModal,
                  selectedEmployeeIsActive,
                  setSelectedEmployee,
                  isTeamsMinionEnabled,
                  isSlackMinionEnabled
                )
              : ''}
          </div>
        </Grid>
      </div>
    </Tooltip>
  );
};

export const getEmployeeTreeNodes = (
  employees:
    | Readonly<EmployeeDetailsEmployeeTreeV2Fragment[]>
    | null
    | undefined,
  viewSlackEmployeeTreeFlag: boolean | undefined,
  grayColor: string,
  openModal: () => void,
  selectedEmployeeIsActive: string,
  setSelectedEmployee: Dispatch<
    SetStateAction<EmployeeDetailsEmployeeTreeV2Fragment | undefined>
  >,
  isTeamsMinionEnabled: boolean | undefined,
  isSlackMinionEnabled: boolean | undefined
): TreeNode<EmployeeTreeNodeValue>[] => {
  if (!employees) {
    return [];
  }
  const employeeTreeNodes: TreeNode<EmployeeTreeNodeValue>[] = [];
  employees.forEach((employee) => {
    employeeTreeNodes.push({
      id: employee?.id || '',
      label: TreeNodeTooltipLabel(
        employee,
        viewSlackEmployeeTreeFlag,
        grayColor,
        openModal,
        selectedEmployeeIsActive,
        setSelectedEmployee,
        isTeamsMinionEnabled,
        isSlackMinionEnabled
      ),
      value: {
        id: employee?.id || '',
        userId: employee?.userId || '',
        fullName: employee?.fullName || '',
        employeeRoleId: employee?.employeeRole?.id,
        division: employee.division,
      },
      isChecked: false,
      isExpanded: false,
      children:
        employee.children && employee.children?.length > 0
          ? getEmployeeTreeNodes(
              employee?.children,
              viewSlackEmployeeTreeFlag,
              grayColor,
              openModal,
              selectedEmployeeIsActive,
              setSelectedEmployee,
              isTeamsMinionEnabled,
              isSlackMinionEnabled
            )
          : undefined,
    });
  });
  return employeeTreeNodes;
};

export const prioritizeEmployeeTreeNodes = (
  employeeTreeNodes: TreeNode<EmployeeTreeNodeValue>[],
  employeeId: string | undefined,
  employeeManagerId: string | undefined
): TreeNode<EmployeeTreeNodeValue>[] => {
  if (employeeId && employeeManagerId) {
    const managerNode = employeeTreeNodes.splice(
      employeeTreeNodes.findIndex((node) => node.id === employeeManagerId),
      1
    )[0];
    if (managerNode) {
      const childrenNodes = managerNode?.children;
      const currentEmployeeNode = childrenNodes?.splice(
        childrenNodes.findIndex((node) => node.id === employeeId),
        1
      )[0];
      if (currentEmployeeNode) {
        childrenNodes?.unshift(currentEmployeeNode);
      }
      employeeTreeNodes?.unshift(managerNode);
    }
  }
  return employeeTreeNodes;
};
