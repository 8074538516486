import { ReactElement, useContext } from 'react';
import {
  TreeNode,
  TreePicker,
  useTreePicker,
} from '../../../components/TreePicker';
import { EmployeeTreeInfoType } from '../EmployeeTree/util';
import {
  EmployeeTreeNodeValue,
  GroupTreeNodeValue,
  SidebarContext,
} from '../util';

interface Props {
  groupTreeNodes: TreeNode<GroupTreeNodeValue>[];
  currentEmployee: EmployeeTreeInfoType;
  updateEmployeeTreeNodeValue: (emp: EmployeeTreeNodeValue) => void;
  group: GroupTreeNodeValue | undefined;
}

export const GroupTreePicker = ({
  groupTreeNodes,
  currentEmployee,
  updateEmployeeTreeNodeValue,
  group,
}: Props): ReactElement => {
  const { setGroupSelected } = useContext(SidebarContext);
  const props = useTreePicker({
    onChange: ({ checked }): void => {
      if (checked.length == 0) {
        updateEmployeeTreeNodeValue({
          id: currentEmployee?.id || '',
          userId: currentEmployee?.userId || '',
          fullName: currentEmployee?.fullName || '',
          employeeRoleId: currentEmployee?.employeeRoleId,
          division: undefined,
          employeeAdditionalDivisions: undefined,
          employeeDepartmentId: currentEmployee?.employeeDepartmentId,
        });
        setGroupSelected(false);
      } else {
        setGroupSelected(true);
      }
    },
    nodes: groupTreeNodes,
    initialExpanded: group?.id ? [group.id] : undefined,
    initialChecked: group?.id ? [group.id] : undefined,
  });
  return <TreePicker fsName="group-tree" fsType={'group'} {...props} />;
};
