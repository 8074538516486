import { MasterFindGlobalStateContext } from '@app/Masterfind/MasterFindGlobalStateProvider';
import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { useTheme } from '@hooks/useTheme';
import { fsEvent, FullstoryEventTypes } from '@utils/fullstory';
import { FC, useContext } from 'react';

export const MasterFindButton: FC = () => {
  const { gray, card } = useTheme();
  const { masterFindGlobalService } = useContext(MasterFindGlobalStateContext);

  return (
    <Button
      id={'master-find-button'}
      fsType={'icon'}
      option="quaternary"
      title="Open Masterfind"
      css={{
        borderRadius: '100%',
        width: 25,
        height: 25,
        textAlign: 'center',
        padding: 0,
        minHeight: 'auto',
        border: 'none',
        background: card.background,
        svg: {
          color: gray[50],
        },
        '&:hover': {
          border: 'none',
        },
      }}
      onClick={(): void => {
        masterFindGlobalService.send('TOGGLE');
        fsEvent(FullstoryEventTypes.MASTERFIND, {
          action: 'Open via Button',
        });
      }}
      name={'master-find-button'}
    >
      <Icon
        i="search"
        color={gray[50]}
        size="sm"
        css={{
          pointerEvents: 'none',
        }}
      />
    </Button>
  );
};
