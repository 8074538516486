/* eslint-disable mastery/known-imports */
import {
  ApolloClient,
  ApolloLink,
  DataProxy,
  defaultDataIdFromObject,
  InMemoryCache,
  NormalizedCacheObject,
  Operation,
  OperationVariables,
  split,
  // eslint-disable-next-line no-restricted-imports
  useApolloClient,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { WebSocketLink } from '@apollo/client/link/ws';
import { Observable, relayStylePagination } from '@apollo/client/utilities';
import {
  getErrorMessageReqIdPrefix,
  REQ_ID_KEY,
} from '@components/shared/mutation';
import { toast } from '@components/SystemAlerts/Toast';
import { LoadInfoFragment } from '@generated/fragments/loadInfo';
import { useUserEmail } from '@hooks/useUserEmail';
import { getUserIdFromStorage } from '@hooks/useUserId';
import {
  BUILD_VERSION,
  environment,
  IS_CONNECT_CARRIER,
  IS_LOCAL_DEV,
  IS_NOT_PREVIEW_OR_PROD,
  IS_PREVIEW_OR_PROD,
  QUERY_PARAM_FLAGS_ENABLED,
} from '@utils/constants';
import { convertForGraphql } from '@utils/graphqlUtils';
import { jsonStringify } from '@utils/json';
import { reportCustomSentryError, sentry } from '@utils/sentry';
import { win } from '@utils/win';
// eslint-disable-next-line mastery/known-imports
import { sha256 } from 'crypto-hash';
import { GraphQLError } from 'graphql';
import { compact, debounce, get, isArray, pickBy, set } from 'lodash-es';
import { useMemo } from 'react';
import { v4 } from 'uuid';
import { config, crossAppConfig } from '../../config';
import { getLocalStorage, setLocalStorage } from '../../utils/localStorage';
import { getAuthHeader } from '../auth/token';
import {
  APP_CLIENT_THROTTLE_SETTINGS,
  APP_QUIET_NETWORK_ERRORS,
  APP_RELOAD_ON_AUTH_TIMEOUT_SYMBOL,
  APP_VERBOSE_ERROR_DISPLAY_SYMBOL,
} from '../GlobalVariables/constants';
import { getGlobalVariable } from '../GlobalVariables/util';
import { RESPONSE_META_KEY } from './constants';

const JWT_EXPIRED_CODE = 'JWT_EXPIRED_SIGNATURE';

const wsLink = new WebSocketLink({
  uri: config?.subscriptionApiEndpoint || '',
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: (): anyOk => ({
      authorization: getAuthHeader(),
    }),
  },
});

const httpLink = createHttpLink({
  uri: ({ operationName, query }: Operation): string => {
    const type = get(query, 'definitions[0].operation', 'query');
    return `${crossAppConfig.apiEndpoint}?${type[0] || 'q'}=${operationName}`;
  },
});

const minionLink = createHttpLink({
  uri: ({ operationName, query }: Operation): string => {
    const type = get(query, 'definitions[0].operation', 'query') ?? '';
    return `${config.dataDictionaryEndpoint}?${
      type[0] || 'q'
    }=${operationName}`;
  },
});

const chooseEndpointLink = split(
  (operation) => {
    return operation.getContext().useMinion === true;
  },
  minionLink,
  httpLink
);

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: getAuthHeader(),
  },
}));

const getClientOpts = (): { name: string; version: string } => {
  let name = `frontend-${environment}`;
  if (IS_CONNECT_CARRIER) {
    name = `frontend-connect-carrier-${environment}`;
  }
  return {
    name,
    version: BUILD_VERSION as string,
  };
};

const getApolloHeaders = (): Record<string, string> => {
  const opts = getClientOpts();
  return {
    ['apollographql-client-name']: opts.name,
    ['apollographql-client-version']: opts.version,
  };
};

export const getExtraHeaders = (): Record<string, Maybe<string | number>> => {
  return {
    [REQ_ID_KEY]: v4(),
    'X-Client-Version': getClientOpts().version,
    'x-mastery-audit-context-initiated-via': 'user',
    'x-mastery-audit-context-initiated-timestamp': Date.now(),
    'x-mastery-audit-context-initiated-id': getUserIdFromStorage(),
    ...getApolloHeaders(),
  };
};

let connectCarrierHeaders: Record<'Company-Id' | 'Tenant-Id', string> = {
  'Company-Id': 'testId',
  'Tenant-Id': 'mm100-dev',
};

// Load headers from localStorage if available
const savedHeaders: Record<'Company-Id' | 'Tenant-Id', string> | null =
  getLocalStorage('connectCarrierHeaders');
if (savedHeaders) {
  connectCarrierHeaders = savedHeaders;
}

/** Set one or more headers for Connect Carrier Gateway. If a header is missing, the current value will be used. */
// ts-unused-exports:disable-next-line
export const setConnectCarrierHeaders = (
  headers: Partial<typeof connectCarrierHeaders>
): void => {
  connectCarrierHeaders = { ...connectCarrierHeaders, ...headers };
  setLocalStorage('connectCarrierHeaders', connectCarrierHeaders);
};

export const getConnectCarrierHeaders = (): typeof connectCarrierHeaders => {
  return connectCarrierHeaders;
};

const customHeadersLink = setContext((_, { headers, useMinion }) => {
  // TODO: minion doesn't like extra headers, CORS errors.
  if (useMinion) {
    return headers;
  }
  return {
    headers: {
      ...headers,
      ...getExtraHeaders(),
      ...(IS_CONNECT_CARRIER ? connectCarrierHeaders : {}),
    },
  };
});

const addMetaLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    set(data, ['data', RESPONSE_META_KEY], {
      headers: operation.getContext().headers,
    });
    return data;
  });
});

// Atlas will need to approve each of these use cases. Please provide a reason as comment.
const throttleSkipOperations = new Set([
  // @reason - AI trucks are created invidiually by single mutation. As of writing, there is no bulk create to use.
  'AICapacityCreateTruck',
  // @reason - Postings are created invidiually by single mutation in parallel. The bulk create is under development.
  'createRoutePosting',
  // @reason - Postings are updated invidiually by single mutation in parallel. The bulk update is under development.
  'updateRoutePosting',
  // @reason - Route max cost are updated invidiually by single mutation in parallel. As of writing, there is no bulk operation to use.
  'updateRouteMaxCost',
  // @reason - The available routes screen responds to locking subscription events by requesting the lock for each route. Investigation to remove this in ME-384831
  'getLock',
  // @reason - need to grab timezones of all data rows presented by AI Capacity - investigate changing this in ME-386698
  'getTimezonesForCities',
  // @reason - AI Capacity includes many different geopickers, one per row. We fire off a request per city. Attempt to fix this in ME-386751
  'getGeographiesV2',
  // @reason - Auto Truck Capacity Creates the next 7 days of auto trucks when a template is created
  'CreateTruckEntry',
  // @reason - Auto Truck Capacity Updates the next 7 days of auto trucks when a template is updated
  'updateTruckEntry',
]);

// If json flag is not set up properly, fall back to essentially no throttling
const fallbackThrottleSettings = {
  limit: 2e12,
  windowMs: 100,
} as const;

const getThrottleSettings = (): typeof fallbackThrottleSettings => {
  const throttleSettings = getGlobalVariable<typeof fallbackThrottleSettings>(
    APP_CLIENT_THROTTLE_SETTINGS
  );
  if (throttleSettings && throttleSettings.limit && throttleSettings.windowMs) {
    return throttleSettings;
  }
  return fallbackThrottleSettings;
};

const createBlankObservable = (): anyOk => {
  // need to silently kill the operation
  return new Observable((observer) => {
    observer.complete();
  });
};

class ClientThrottleError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ClientThrottleError';
  }
}

function createThrottleLink(): ApolloLink {
  const operationTimestamps: Map<string, number[]> = new Map();
  const blockedOperations = new Set<string>([]);
  const { limit, windowMs } = getThrottleSettings();

  return new ApolloLink((operation, forward) => {
    const currentOp = operation.operationName;
    if (throttleSkipOperations.has(currentOp)) {
      return forward(operation);
    }
    const now = Date.now();
    const timestamps = operationTimestamps.get(currentOp) || [];
    if (blockedOperations.has(currentOp)) {
      return createBlankObservable();
    }

    // Remove timestamps outside the sliding window
    while (timestamps.length > 0 && now - (timestamps[0] ?? 0) > windowMs) {
      timestamps.shift();
    }

    if (timestamps.length >= limit) {
      if (IS_NOT_PREVIEW_OR_PROD) {
        setTimeout(() => {
          blockedOperations.add(currentOp);
          throw new ClientThrottleError(
            `The operation ${currentOp || 'unknown'} was called ${
              timestamps.length
            } times within ${windowMs}ms. As a result, the app has been terminated. Fix the issue to unblock.`
          );
        }, 0);
      }
      // production scenario
      reportCustomSentryError(
        `Operation ${currentOp} was called ${timestamps.length} times within ${windowMs}ms.`,
        {
          tags: {
            team: 'atlas',
          },
        }
      );
      if (IS_PREVIEW_OR_PROD) {
        // need to silently kill the operation to avoid server pressure
        return createBlankObservable();
      }
    }

    // Add the current timestamp to the queue
    timestamps.push(now);
    operationTimestamps.set(currentOp, timestamps);

    // Clean up old timestamps periodically to avoid memory leaks
    if (operationTimestamps.size > 1000) {
      operationTimestamps.forEach((timestamps, key) => {
        if (timestamps.length === 0) {
          operationTimestamps.delete(key);
        }
      });
    }

    return forward(operation);
  });
}
interface ExtendedLoad extends LoadInfoFragment {
  truckId: string;
}

const cleanVariablesForMutationLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = convertForGraphql(operation.variables);
  }
  return forward(operation);
});

const ERROR_AND_RELOAD_TIMEOUT_MS = 1000 * 10;

const errorAndReloadDebounced = debounce(
  () => {
    if (getGlobalVariable(APP_RELOAD_ON_AUTH_TIMEOUT_SYMBOL)) {
      const timeout = setTimeout(
        () => win.location.reload(),
        ERROR_AND_RELOAD_TIMEOUT_MS
      );
      toast({
        content: `Your browser window has been open too long, refreshing in ${Math.round(
          ERROR_AND_RELOAD_TIMEOUT_MS / 1000
        )} seconds...`,
        type: 'error',
        toastOptions: {
          onClose: () => clearTimeout(timeout),
          autoClose: false,
        },
      });
    }
  },
  200,
  // we want to fire off this event immediately, not wait until things settle
  // network requests might keep firing, so take the first invocation
  { leading: true, trailing: false }
);

interface MasteryGraphQLError extends Omit<GraphQLError, 'extensions'> {
  extensions: {
    code?: string;
    details?: string;
    serviceName?: string;
    exception?: {
      stacktrace?: string[];
    };
  };
}

const getErrorMessage = (message?: string): string => {
  const isInputError = (message || '').startsWith(
    'Variable "$input" got invalid value'
  );
  if (isInputError) {
    return 'Invalid input variable';
  }
  return message || 'Unknown';
};

const jwtExpiredTrace =
  'AuthenticationError: Context creation failed: Invalid JWT: TokenExpiredError: jwt expired.';

// Mostly built-in graphql error codes that highlight an issue with the FE request to BE
// https://www.apollographql.com/docs/apollo-server/data/errors/#built-in-error-codes
const optInCodes = new Set([
  'GRAPHQL_PARSE_FAILED',
  'GRAPHQL_VALIDATION_FAILED',
  'PERSISTED_QUERY_NOT_SUPPORTED',
  'OPERATION_RESOLUTION_FAILURE',
  'BAD_USER_INPUT',
]);

const errorLink = onError(({ graphQLErrors, operation, networkError }) => {
  const type = get(operation.query, 'definitions[0].operation', 'query');
  const opName = operation.operationName;
  // For some reason, graphQLErrors will pass the isArray function, but will not have a map method...
  // https://sentry.io/organizations/mastery-logistics-systems/issues/1901239299
  if (isArray(graphQLErrors)) {
    const errorsArr = graphQLErrors as MasteryGraphQLError[];
    errorsArr.map((errorObj) => {
      try {
        const { message, locations, path, extensions } = errorObj;
        const { code, details, serviceName } = extensions || {};
        const firstStackTrace =
          get(extensions, 'exception.stacktrace[0]') || '';
        if (code === JWT_EXPIRED_CODE || firstStackTrace === jwtExpiredTrace) {
          errorAndReloadDebounced();
          if (getGlobalVariable(APP_QUIET_NETWORK_ERRORS)) {
            return;
          }
        }

        if (!optInCodes.has(code || '')) {
          return;
        }

        let exceptionData: anyOk;
        try {
          exceptionData = {
            tags: pickBy({
              service: serviceName || '',
              code: code || '',
              graphql: 'true',
              path: path?.join('.') || '',
              operationType: type,
              operation: opName,
            }),
            extra: pickBy({
              locations: jsonStringify(locations),
              details,
              message,
            }),
          };
        } catch {
          // noop
        }

        // https://blog.sentry.io/2019/01/17/debug-tough-front-end-errors-sentry-clues#group-errors-your-way-with-fingerprints
        sentry.withScope((scope) => {
          scope.setFingerprint([
            serviceName,
            code,
            type,
            opName,
            firstStackTrace,
          ]);
          reportCustomSentryError(
            new Error(getErrorMessage(message)),
            exceptionData
          );
        });
      } catch (outerErr) {
        reportCustomSentryError(outerErr);
      }
    });
  } else if (graphQLErrors) {
    reportCustomSentryError(jsonStringify(graphQLErrors));
  }

  // This code propagates a better message to the user with enhanced context.
  // Apollo client treats network errors differently, and as of writing makes it difficult to pass along custom data with the error. So we encode our info in the error message directly.
  if (networkError?.message) {
    if (!getGlobalVariable(APP_VERBOSE_ERROR_DISPLAY_SYMBOL)) {
      networkError.message =
        'There was an error processing your request. An automatic report has been generated.';
    } else {
      const prefix = getErrorMessageReqIdPrefix(
        operation.getContext() as anyOk
      );
      const firstErr = get(graphQLErrors, '0.message');
      const finalMessage = firstErr || networkError.message;
      networkError.message = `${prefix}${finalMessage}`;
    }
  }
});

const persistedQueriesLink = createPersistedQueryLink({
  sha256,
});

// Used in cypress component test rendering
// ts-unused-exports:disable-next-line
export const getClient = (kwargs: {
  persistedQueries: boolean;
  connectCarrier?: boolean;
}): ApolloClient<NormalizedCacheObject> => {
  const link = ApolloLink.from(
    compact([
      kwargs.persistedQueries ? persistedQueriesLink : undefined,
      cleanVariablesForMutationLink,
      authLink,
      customHeadersLink,
      createThrottleLink(),
      addMetaLink,
      errorLink,
      chooseEndpointLink,
    ])
  );

  const splitLink = split(
    // split based on operation type
    (op) => {
      const isSubscription = !!op.query.definitions.find(
        (def) =>
          def.kind === 'OperationDefinition' && def.operation === 'subscription'
      );
      return isSubscription;
    },
    wsLink,
    link
  );

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          allAvailableRoutes: relayStylePagination(),
          allAvailableTrucks: relayStylePagination(),
          allAvailableGateReservations: relayStylePagination(),
          allAvailableContainers: relayStylePagination(),
          matches: relayStylePagination(),
          gateReservationMatches: relayStylePagination(),
          containerMatches: relayStylePagination(),
          getContainersForCarrier: relayStylePagination(),
          getGateReservationsForCarrier: relayStylePagination(),
          getTruckEntriesForCarrier: relayStylePagination(),
          getTruckEntries: relayStylePagination(),
          customerCommitments: relayStylePagination(),
          clientExceptionRecords: relayStylePagination(),
          incidentsV2: relayStylePagination(),
          tasksPaginatedV2: relayStylePagination(),
          carrierCommitments: relayStylePagination(),
          searchMatchingRoutesForTruck: relayStylePagination(),
          searchMatchingRoutesForContainer: relayStylePagination(),
          searchMatchingTrucksForRoute: relayStylePagination(),
          searchMatchingGateReservationsForRoute: relayStylePagination(),
          searchMatchingContainersForRoute: relayStylePagination(),
          allEdiManagementTransactions: relayStylePagination(),
        },
      },
      EmployeeAdditionalDivisions: {
        keyFields: ['id', 'employeeId'],
      },
      CustomerSearch: {
        keyFields: ['id', 'customerAddressId'],
      },
      CarrierSearch: {
        keyFields: ['id', 'carrierAddressId'],
      },
      SeerMainPageRouteBoardDetails: {
        keyFields: ['routeNumber'],
      },
      FacilitySearch: {
        keyFields: ['id', 'address', 'state'],
      },
      SeerMainPageTrackingBoard: {
        keyFields: ['routeNumber'],
      },
      AccountingUnvoucheredCarrierProcessingQueue: {
        keyFields: ['routeId', 'vendorId'],
      },
      SeerFacilityTrackingBoard: {
        keyFields: ['routeNumber', 'destination'],
      },
      SeerTrackingPage: {
        keyFields: ['routeNumber'],
      },
      TrackingPage: {
        keyFields: ['routeNumber'],
      },
      MainPageTrackingBoard: {
        keyFields: ['routeNumber'],
      },
      FacilityTracking: {
        keyFields: ['routeNumber'],
      },
      StopAddress: {
        keyFields: ['id', 'city'],
      },
      SeerLoadSearch: {
        keyFields: ['routeId'],
      },
      SeerCarrierRoute: {
        keyFields: ['routeId'],
      },
      CustomerCustomerRelationship: {
        keyFields: ['id', 'customerId'],
      },
      SeerCustomerOrder: {
        keyFields: ['routeId'],
      },
      SeerFacilityRoute: {
        keyFields: ['routeId'],
      },
      CarrierCarrierRelationship: {
        keyFields: ['id', 'carrierId'],
      },
      BidConnection: {
        merge: true,
      },
      BidLaneConnection: {
        merge: true,
      },
      AvailableRoute: {
        merge: true,
      },
      MarkupPagedResponse: {
        merge: true,
      },
      PagedResponse: {
        merge: true,
      },
      AvailableTruck: {
        keyFields: ['truckPostingId'],
      },
      AvailableRouteDivision: {
        keyFields: ['routeId', 'id'],
      },
      CrmOpportunityOutputV2: {
        keyFields: ['opportunityId', 'entityId'],
      },
      CrmOpportunityBusinessUnitOutputV2: {
        keyFields: ['opportunityBusinessUnitId'],
      },
      crmOpportunityContactOutput: {
        keyFields: ['Opportunity_Contact_Id', 'Contact_Id'],
      },
      crmOpportunityEquipmentOutput: {
        keyFields: ['Opportunity_Equipment_Id', 'Opportunity_Id'],
      },
      crmOpportunityIbRegionOutput: {
        keyFields: ['Opportunity_IBRegion_Id', 'Opportunity_Id'],
      },
      crmOpportunityModeOutput: {
        keyFields: ['Opportunity_Mode_Id', 'Opportunity_Id'],
      },
      crmOpportunityObRegionOutput: {
        keyFields: ['Opportunity_OBRegion_Id', 'Opportunity_Id'],
      },
      CrmOpportunityRepsOutputV2: {
        keyFields: ['opportunityRepId', 'opportunityId'],
      },
      crmOpportunitySizeOutput: {
        keyFields: ['Opportunity_Size_Id', 'Opportunity_Id'],
      },
      crmOpportunitySolutionOutput: {
        keyFields: ['Opportunity_Solution_Id', 'Opportunity_Id'],
      },
      SeerMainPageRouteBoardPending: {
        keyFields: ['orderId'],
      },
      GeographyServiceRecord: {
        keyFields: ['sourceId'],
      },
      OptiMatchSession: {
        keyFields: ['sessionId'],
      },
      OptiMatchSolution: {
        keyFields: ['sessionId'],
      },
      OptiMatchDriver: {
        keyFields: ['capacityId'],
        fields: {
          availableTruck: {
            merge(existing, incoming): anyOk {
              return incoming ?? existing;
            },
          },
        },
      },
      OptiMatchRoute: {
        fields: {
          availableRoute: {
            merge(existing, incoming): anyOk {
              return incoming ?? existing;
            },
          },
        },
      },
      MainPageRouteBoardOpen: {
        keyFields: ['routeNumber'],
      },
      MainPageRouteBoardArrivedAtDestination: {
        keyFields: ['routeNumber'],
      },
      MainPageRouteBoardArrivedAtOrigin: {
        keyFields: ['routeNumber'],
      },
      MainPageRouteBoardCompleted: {
        keyFields: ['routeNumber'],
      },
      MainPageRouteBoardCovered: {
        keyFields: ['routeNumber'],
      },
      MainPageRouteBoardDispatched: {
        keyFields: ['routeNumber'],
      },
      MainPageRouteBoardInTransit: {
        keyFields: ['routeNumber'],
      },
      MainPageRouteBoardPending: {
        keyFields: ['orderId', 'routeId'],
      },
      MainPageRouteBoardIncomplete: {
        keyFields: ['orderId', 'routeId'],
      },
      MainPageRouteBoardPreTender: {
        keyFields: ['routeNumber'],
      },
      MainPageRouteBoardTender: {
        keyFields: ['routeNumber'],
      },
      VoucheredDrivers: {
        keyFields: ['id', 'voucherLoadRoute'],
      },
    },
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    dataIdFromObject: (obj) => {
      const { __typename: n } = obj;
      if (n === 'calculateVatCheckedModel') {
        // this query/type is at a tenant level, there is no need for id
        // but to use optimistic options from apollo client we need to generate an identifier
        return 'calculateVatCheckedModel:tenant';
      } else if (n === 'TempLoad') {
        // we shouldn't have to do this if the ID for the TempLoad object is distinct per Truck + Load combo.
        // As of now, it is not - and loads with the same id, but different DDH or ODH values happen, and the cache gets confused
        // So we stabilize the cache by narrowing down the exact id ourselves
        const load = obj as unknown as ExtendedLoad;
        try {
          // this load.truck access is dependent on adding the truck object in the response above.
          return `TempLoad:${load.id}-TruckPosting:${load.truckId}`;
        } catch {
          // hopefully this never happens, but if it does then we are effectively bailing out of the cache as an escape hatch
          return `TempLoad:${Math.random().toString()}`;
        }
      } else if (n === 'RouteVendorCost') {
        const routeId = obj.routeId || obj.routeIdAsNullable;
        const vendorId = obj.vendorId || obj.vendorIdAsNullable;
        if (routeId && vendorId) {
          return `RouteVendorCost:${routeId}-${vendorId}`;
        }
      }
      return defaultDataIdFromObject(obj);
    },
    possibleTypes: {
      LoadTender: ['DrayNotification', 'RailBilling', 'RateConfirmationV2'],
    },
  });

  return new ApolloClient({
    link: splitLink,
    cache,
    ...getClientOpts(),
    connectToDevTools: QUERY_PARAM_FLAGS_ENABLED,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
  });
};

const persistedQueriesOptOut = new Set(['keycloaksuperuser@mastery.net']);

interface ClientOpts {
  connectCarrier?: boolean;
}

export const useMasteryApolloClient = (
  kwargs?: ClientOpts
): ApolloClient<NormalizedCacheObject> => {
  const email = useUserEmail();
  const emailInOptOut = persistedQueriesOptOut.has(
    email?.toLowerCase() ?? 'noop'
  );
  const optOutOfPersistedQueries = emailInOptOut || IS_LOCAL_DEV;
  return useMemo(() => {
    return getClient({
      persistedQueries: optOutOfPersistedQueries ? false : true,
      ...kwargs,
    });
  }, [emailInOptOut]);
};

export const useFragmentReader = (): (<T, TVariables = OperationVariables>(
  options: DataProxy.Fragment<TVariables, T>,
  optimistic?: boolean
) => T | null) => {
  const client = useApolloClient();
  return (options, optimistic) => client.readFragment(options, optimistic);
};
