import { IsInDialogContext } from '@components/Dialog/context';
import { Icon, IconColorProp, IconSizeProp } from '@components/Icon';
import { useHasPermissionFromContext } from '@components/PermissionsContext';
import { useFlagMe49437DropdownComponentDefaultToReactPortal } from '@generated/flags/ME-49437-dropdown-component-default-to-react-portal';
import { useClickAwayConditional } from '@hooks/useClickAwayConditional';
import { useEffectAfterMount } from '@hooks/useEffectAfterMount';
import { useFullstoryElement } from '@hooks/useFullstory';
import { useTheme } from '@hooks/useTheme';
import { Placement } from '@popperjs/core';
import {
  FullStoryElementType,
  FullStoryElements,
  FullStoryTypes,
  extractFsString,
} from '@utils/fullstory';
import { useWorkflow } from '@utils/fullstory/context';
import { AUTOCOMPLETE } from '@utils/zIndex';
import Downshift, { StateChangeOptions } from 'downshift';
import { get, identity, isString, last, omit, pick } from 'lodash-es';
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  KeyboardEvent,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import {
  AutoComplete,
  Props as AutoCompleteProps,
  ITEM_CONTAINER_VERT_MARGIN,
  Shell,
  getStringFromItem,
} from '../AutoComplete';
import { ReadOnlyField } from '../Field/ReadOnlyField';
import { Label } from '../FieldLabel';

const MIN_HEIGHT = 32;

export const BLANK_SELECTED_ITEM_LABEL = '---';
export const BLANK_OPTION = { value: '', label: BLANK_SELECTED_ITEM_LABEL };

type PickedProps<Item> = Pick<
  AutoCompleteProps<Item>,
  | 'id'
  | 'initialSelectedItem'
  | 'inputProps'
  | 'items'
  | 'itemToString'
  | 'label'
  | 'onChange'
  | 'restrictItemContainerWidth'
  | 'filterOnLabel'
  | 'dropup'
>;

export const DirectionalArrow: FC<{
  up?: boolean;
  size?: IconSizeProp;
  color?: IconColorProp;
}> = ({ up, size = 'sm', color = 'text', ...rest }) => (
  <Icon
    i={up ? 'angleUp' : 'angleDown'}
    css={{ transform: 'scaleX(.8)' }}
    size={size}
    color={color}
    {...rest}
  />
);

// ts-unused-exports:disable-next-line
export const DROPDOWN_MIN_HEIGHT = 31;

export interface Props<Item> extends PickedProps<Item> {
  selectedItem?: AutoCompleteProps<Item>['selectedItem'] | null;
  disabled?: boolean;
  readOnly?: boolean;
  renderDropdownInPopper?: boolean;
  buttonText?: string | ReactNode;
  name?: string;
  /** The item selection area will appear above the button */
  dropup?: boolean;
  dropdownZIndex?: number;
  showSearchIcon?: boolean;
  disableClickBubble?: boolean;
  onInputValueChange?: (inputValue: string) => void;
  loading?: boolean;
  initialSelectedItem?: Shell<Item>;
  buttonProps?: DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >;
  onClose?: () => void;
  autoCompleteProps?: Partial<Omit<AutoCompleteProps<Item>, 'loading'>>;
  fsParent?: string;
  fsName?: string;
  fsType?: FullStoryTypes;
  fsElement?: FullStoryElementType;
  fsSearchElement?: FullStoryElementType;
  fsItemElement?: FullStoryElementType;
}

export const Dropdown = <Item extends unknown>(
  props: Props<Item>
): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const mainRef = useRef<HTMLDivElement>(null);
  const defaultToPortalForPopper =
    useFlagMe49437DropdownComponentDefaultToReactPortal();

  const {
    showSearchIcon,
    items,
    inputProps,
    onChange,
    itemToString,
    label,
    loading,
    initialSelectedItem,
    selectedItem,
    disabled,
    dropdownZIndex,
    buttonProps,
    buttonText,
    restrictItemContainerWidth,
    onClose,
    autoCompleteProps,
    dropup: dropupProp,
    renderDropdownInPopper,
    disableClickBubble,
    fsParent,
    fsName,
    fsType = 'dropdown',
    fsSearchElement = FullStoryElements.FIELD_SEARCH_BAR,
    fsItemElement = FullStoryElements.FIELD_MENU_ITEM,
    fsElement = FullStoryElements.FIELD_INPUT,
    ...rest
  } = props;
  const { gray } = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const acWrapperRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<Maybe<Shell<Item>>>(
    initialSelectedItem ?? selectedItem
  );
  const setData = selectedItem ? selectedItem : selected;

  const shouldUsePopper = Boolean(renderDropdownInPopper || dropupProp);

  const { getFsComponentProps } = useFullstoryElement();
  const { id } = useWorkflow();
  const fsNameValue: string = extractFsString([
    fsName,
    label,
    rest?.name,
    rest?.id,
  ]);

  useClickAwayConditional({
    ref: acWrapperRef,
    determineFunction: (event): boolean => {
      return (
        event.target !== buttonRef.current &&
        event.target !== mainRef.current &&
        event.target !== acWrapperRef.current &&
        !acWrapperRef.current?.contains(event.target as Node)
      );
    },
    onClickAway: () => {
      setIsOpen(false);
    },
  });

  useEffect(() => {
    if (!isOpen) {
      onClose?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const selectedItemTrigger = selectedItem?.id ?? selectedItem;

  useEffectAfterMount(() => {
    setSelected(selectedItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemTrigger]);

  const filteredItems = items.filter((obj) => !obj.hidden);
  const dataTestId = get(rest, 'data-testid', undefined);
  useEffect(() => {
    if (disabled) {
      setIsOpen(false);
    }
  }, [disabled, isOpen]);

  const buttonTextDisplay = buttonText || '';

  const itemAsString: string | ReactNode =
    buttonTextDisplay ||
    getStringFromItem(setData, itemToString) ||
    inputProps?.placeholder;

  const [userCanEdit, permissionScope] = useHasPermissionFromContext();
  const readOnly = !userCanEdit || props.readOnly;
  const isInDialog = useContext(IsInDialogContext);
  const dropupPlacement: Placement = 'top-end';

  // Set popper same width as reference
  // https://github.com/popperjs/popper-core/issues/794
  const modifiers = useMemo(
    () => [
      {
        name: 'sameWidth',
        enabled: true,
        phase: 'beforeWrite' as fixMe,
        requires: ['computeStyles'],
        fn({ state }: fixMe): void {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        },
        effect({ state }: fixMe): void {
          state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
        },
      },
      { name: 'offset', options: { offset: [0, 2] } },
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: [dropupPlacement, 'bottom-start'],
          fallbackPlacements: [dropupPlacement, 'bottom-start'],
          rootBoundary: 'document',
        },
      },
    ],
    []
  );

  const {
    styles: popperStyles,
    attributes: popperAttributes,
    state,
  } = usePopper(buttonRef.current, acWrapperRef.current, {
    placement: 'auto-start',
    strategy: 'fixed',
    modifiers,
  });

  const dropup = shouldUsePopper && state?.placement === dropupPlacement;

  if (readOnly) {
    return (
      <ReadOnlyField
        data-fieldname={rest.name}
        name={rest.name}
        data-scope={permissionScope}
      >
        {itemAsString}
      </ReadOnlyField>
    );
  }

  const selector = isInDialog ? '[data-testid="dialog"]' : '#root';

  const maybePortal =
    shouldUsePopper && defaultToPortalForPopper && isOpen
      ? (content: ReactNode): anyOk =>
          createPortal(
            content,
            last(document.querySelectorAll(selector)) as Element
          )
      : identity;

  return (
    <div
      data-scope={permissionScope}
      css={{ position: 'relative' }}
      {...rest}
      {...omit(rest, ['labelHidden', 'name'])}
      data-fieldname={rest.name}
      title={isString(itemAsString) ? itemAsString : undefined}
      data-selected={isString(selected?.label) ? selected?.label : selected?.id}
      ref={mainRef}
    >
      {label && <Label>{label}</Label>}
      <button
        {...getFsComponentProps({
          name: fsNameValue,
          element: fsElement ?? FullStoryElements.FIELD_INPUT,
          parent: fsParent ?? id,
          type: fsType,
        })}
        ref={buttonRef}
        aria-expanded={isOpen ? true : false}
        {...buttonProps}
        title={isString(itemAsString) ? itemAsString : undefined}
        data-isempty={!itemAsString || undefined}
        type="button"
        className="input"
        disabled={disabled}
        {...pick(rest, ['data-testid'])}
        aria-haspopup="listbox"
        css={{
          position: 'relative',
          paddingRight: 25,
          textAlign: 'left',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          minHeight: MIN_HEIGHT,
        }}
        onClick={(e): void => {
          disableClickBubble && e.stopPropagation();
          setIsOpen((s) => !s);
        }}
        onKeyDown={(event: KeyboardEvent): void => {
          if (event.key == 'ArrowUp') {
            setIsOpen(false);
          }
          if (event.key == 'ArrowDown') {
            setIsOpen(true);
          }
        }}
        /* Prevent mouse down so that onClick doesn't intefere with the input onBlur */
        onMouseDown={(event): void => event.preventDefault()}
        data-testid="expand"
      >
        {itemAsString}
        <DirectionalArrow
          up={isOpen}
          css={{
            top: 0,
            right: 10,
            pointerEvents: 'none',
            position: 'absolute',
            transform: 'translateY(70%)',
            opacity: disabled ? '.4' : 1,
          }}
        />
      </button>
      {maybePortal(
        <div
          {...(shouldUsePopper && popperAttributes.popper)}
          ref={acWrapperRef}
          data-testid={dataTestId ? `${dataTestId}-wrapper` : undefined}
          css={{
            position: 'absolute',
            minWidth: restrictItemContainerWidth
              ? '100% !important'
              : undefined,
            width: shouldUsePopper ? undefined : '100% !important',
            boxShadow: isOpen ? '0 0 5px 3px rgba(0,0,0,0.03)' : undefined,
            top: 'calc(100% + 5px)',
            zIndex: dropdownZIndex ?? AUTOCOMPLETE,
            ul: {
              marginTop: 0,
              border: `1px solid ${gray[70]}`,
              borderTop: dropup ? undefined : 'none',
              borderBottom: dropup ? 'none' : undefined,
              borderRadius: '0 0 2px 2px',
            },
            "input:not([type='checkbox'])": {
              borderRadius: '2px 2px 0 0',
              margin: 0,
            },
            ...(shouldUsePopper && popperStyles.popper),
            marginBottom: dropup ? ITEM_CONTAINER_VERT_MARGIN : undefined,
          }}
        >
          {isOpen && (
            <AutoComplete
              itemToString={itemToString}
              fsParent={fsParent}
              fsName={fsNameValue}
              fsType={fsType}
              fsSearchElement={fsSearchElement}
              fsItemElement={fsItemElement}
              css={
                !restrictItemContainerWidth && {
                  position: 'absolute',
                  minWidth: '100%',
                  'ul[role=listbox]': {
                    position: dropup ? undefined : 'relative',
                    scrollbarColor: 'auto',
                  },
                  ...(dropup && { bottom: 0 }),
                }
              }
              {...autoCompleteProps}
              name={rest.name}
              isOpen={isOpen}
              loading={loading}
              restrictItemContainerWidth={restrictItemContainerWidth}
              items={filteredItems}
              showSearchIcon={showSearchIcon}
              filterOnLabel
              onChange={(item): void => {
                if (item) {
                  if (onChange) {
                    onChange(item);
                  }
                  buttonRef.current?.focus();
                  setSelected(item);
                }
                setIsOpen(false);
              }}
              inputProps={{
                ...omit(inputProps, 'placeholder'),
                autoFocus: true,
                [`data-testid`]: dataTestId ? `${dataTestId}-input` : undefined,
              }}
              downshiftStateReducer={(
                state,
                changes
              ): Partial<StateChangeOptions<Shell<Item>>> => {
                switch (changes.type) {
                  case Downshift.stateChangeTypes.keyDownEscape:
                  case Downshift.stateChangeTypes.keyDownEnter:
                  case Downshift.stateChangeTypes.clickItem:
                  case Downshift.stateChangeTypes.mouseUp:
                  case Downshift.stateChangeTypes.blurInput:
                    setIsOpen(false);
                    buttonRef.current?.focus();
                    return {
                      ...changes,
                      isOpen: false,
                      highlightedIndex: state.highlightedIndex,
                    };
                  default:
                    return changes;
                }
              }}
              dropup={dropup}
              disableSelectOnTab
              initialIsOpen
            />
          )}
        </div>
      )}
    </div>
  );
};
